import React, { Component, useState } from "react";
import {
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    ModalBody,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Grid, Select, TextField } from '@mui/material';
import logonew from '../images/logonew.png';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Apps from '@mui/icons-material/Apps';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import '../container/MainPage.css';
import Register from "./Register";
import dbuglobal from './Images/dbuglobal.jpg'



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const Nav1 = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Grid item xs={12} lg={12} md={12} sm={12}>
            <Navbar
                expand="xl"

                style={{ backgroundColor: "#fff", paddingRight: '1rem', paddingLeft: '1rem', flexWrap: '-moz-initial3', height: '5.5rem' }}
            >
                <Navbar.Brand href="https://www.deshbhagatonline.com/" style={{ marginRight: '0%', marginLeft: '7%' }}>
                    <img src={dbuglobal} height="83px" alt="logo" width="174px" />
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="mx-auto"  >


                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onMouseEnter={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            style={{ marginLeft: '-100px', height: '35px' }}
                        >
                            <Apps style={{ color: 'white' }} />  All Courses
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            style={{}}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: '',
                                horizontal: 'left',
                            }}
                        >

                            <MenuItem onClick={handleClose} disableRipple >
                                <Link to="/business">  Management</Link>
                                {/* Collabration and Project Management */}
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/human-services"> Humanities</Link>
                            </MenuItem>
                            {/* <Divider sx={{ my: 0.5 }} /> */}
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health"> Healthcare Science </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/trades"> Workshop Practice </Link>

                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health"> Computer Science and IT </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health"> Creative Industries</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health"> Creative Industries</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health"> Finance and Accounting</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health">Healthcare Science</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>

                                <Link to="/health"> Community Services</Link>
                            </MenuItem>
                        </StyledMenu>


                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginLeft: '70px', height: '35px', border: "1px solid #118aef", boxShadow: 'none' }}
                        >
                            <IconButton sx={{ p: '10px' }} aria-label="menu">
                                <SearchIcon sx={{ color: '#1179ef' }} />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1, color: '#1179ef' }}
                                placeholder="What do you want to learn?"
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">

                            </IconButton>
                            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                                {/* <DirectionsIcon /> */}
                            </IconButton>
                        </Paper>

                        <Form style={{ marginLeft: '' }} >
                            {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}
                            {/* <Button style={{ backgroundColor: '#fff', border: '4px', color:'#51565e'}}> */}
                            <Register />

                        </Form>


                    </Nav>



                </Navbar.Collapse>

            </Navbar>
        </Grid>
    )
}
export default Nav1;