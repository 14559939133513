// material-ui
import { useTheme } from '@mui/material/styles';

//   if you want to use image instead of <svg> uncomment following.

// import logoDark from 'assets/images/logo-dark.svg';
import logo from '../../src/ILETS/Images/dbuglobal.jpg';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        //   if you want to use image instead of svg uncomment following, and comment out <svg> element.
        
        <img src={logo} alt="Berry" width="auto" height="40px"  paddingLeft='10' style={{paddingLeft:'40%'}} />

        
    );
};

export default Logo;
