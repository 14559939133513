import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
// import Ards from './fullpage';
import Ardss from './Ardss';
import Register from "./Register";
import logo from '../MRSSPTU.png'
import TabComponent from './TabComponent copy';


export default function RegisterCard(props) {
  return (
        <Grid container sx={{height:"100vh",width:"100%", backgroundColor: '#fff'}}>
           

            <Grid item xs={12} md={12} lg={12}>
                  <TabComponent id={props.id} search={props.search}/>  
            </Grid  >

         </Grid>
  );
}
