import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { UserContext } from 'ContextFiles';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default function RecipeReviewCard(videoId) {
  console.log(videoId)
  const {user}=React.useContext(UserContext)
  const [expanded, setExpanded] = React.useState(false);
  const [comment, setComment] = React.useState([]);
  const current = new Date();
  const createdAt = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const [data, setdata] = React.useState({
    user:user,
    videoId:videoId,
    createdAt:createdAt,
    comment:"",
    discussionId:uuidv4()
  });
  const postDiscussion =async(event)=>{
    console.log(data)
    event.preventDefault();
     await axios({
      url:"https://cms.keewesolutions.com/db/postDiscussion",
      method:"post",
      data:data
    }).then((response)=>{
      console.log(response.data)
      getDiscussion()
      setdata({
        user:user,
        videoId:videoId,
        createdAt:createdAt,
        comment:"",
        discussionId:uuidv4()
      })
    })
       }

  const getDiscussion = async()=>{await axios({
           url:"https://cms.keewesolutions.com/db/getDiscussion",
           method:"post",
           data:videoId
           
         }).then(res=>{
           console.log("qwerty",res);
           setComment(res.data)
  })}

  React.useEffect(()=>{

    getDiscussion();

  },[])

  

  return (

    <Grid container>
    
    <Grid item lg={12} xs={7}>
    <Card sx={{ height:"auto"}}>
      <Box sx={{display:"flex",paddingBottom:"5px"}}>
      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
      <Box>
      <Typography sx={{paddingLeft:"5px"}}>{user.name}</Typography>
      <Typography sx={{paddingLeft:"5px",fontSize:'12px'}}>{createdAt}</Typography>
      </Box>
      </Box>
      <TextField  value={data.comment} label="Discussion" id="fullWidth"  onChange={event => { setdata({ ...data,comment:event.target.value }) }} />
      <Button onClick={postDiscussion}>Post Discussion</Button>
      
    </Card>

    {comment.map((item,index)=>{
      // console.log(item);
      return (
    <Card sx={{ height:"auto",padding:"1rem 0"}}>
      <Box sx={{display:"flex",paddingBottom:"5px"}}>
      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">{item.userName[0]}</Avatar>
      <Box>
      <Typography sx={{paddingLeft:"5px"}}>{item.userName}</Typography>
      <Typography sx={{paddingLeft:"5px",fontSize:'12px'}}>{item.createdAt}</Typography>
      </Box>
      </Box>
      <Typography>{item.comment}</Typography>
      {/* <TextField  label="Discussion" id="fullWidth"  onChange={event => { setdata({ ...data,comment:event.target.value }) }} />
      <Button onClick={postDiscussion}>Post Discussion</Button> */}
      
    </Card>)
    })}
    
    <Grid item lg={3}/>
    </Grid>
    </Grid>
  );
}
