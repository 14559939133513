import * as React from 'react';
import './Card.css';
import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

// const ExpandMore = styled((props) => {
//     const { expand, ...other } = props;
//     return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//     transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest
//     })
// }));

export default function RecipeReviewCard1(props) {
    let { image, title, date, description } = props;
    // const [expanded, setExpanded] = React.useState(false);

    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    return (
        <Grid sx={{ maxWidth: 330, margin: '1%' }}>
            <CardMedia className='yourcourse' component="img" height="194" image={image} alt="Paella dish" sx={{borderRadius: '10px'}}/>
            <CardHeader
                action={<IconButton aria-label="settings"></IconButton>}
                title={title}
                subheader={date}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <NavLink to={'#'} style={{ textDecoration: 'none' }}>
                    <Button variant="outlined" className='btn'>
                        <Typography>View Course</Typography>
                    </Button>
                </NavLink>
            </CardActions>
        </Grid>
    )
}
