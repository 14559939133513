import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Divider, Grid, Button } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Box } from "@mui/system";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Week1({courseId}) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [expanded1, setExpanded1] = React.useState("panel1");
  const [expanded2, setExpanded2] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "4px", transform: "scale(1)" }}
    >
      •
    </Box>
  );

 
  const [contentArray, setContentArray] = React.useState([])
  React.useEffect(()=>{
    const getCourseContent = async  (courseId) => {
      try {
        const weeklyCourseRequest = await axios({
          url: "https://backend.keewesolutions.com/weekly/course",
          data: {
            "courseId": courseId
          },
          method: "POST",
          
        })
        const courseData = weeklyCourseRequest.data.response.course
        setContentArray(courseData)
        console.log(courseData)
      } catch (error) {
        console.log(error)
      }
    }
    getCourseContent(courseId)
  },[])
  return (
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={12} lg={8} sx={{ padding: "2%" }}>
        {contentArray.map((item, index) => {
          return (
            <Accordion
              expanded={expanded === `panel_${index}`}
              onChange={handleChange(`panel_${index}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography sx={{ fontWeight: "700" }}>Module {index+1} {item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              
              <a href={item.resource} style={{textDecoration:"none"}}><Button sx={{ fontWeight: "700" }}>Click Here To Download Module Resource</Button></a>
              
                               {item.topics.map((item1) => {
                  return (
                    <Accordion
                      expanded={expanded1 === "panel1"}
                      onChange={handleChange1("panel1")}
                      sx={{ marginTop: "2%" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Typography sx={{ fontWeight: "700" }}>
                          {item1.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      {item1.subTopics.map((item2) => {
                  return (
                        <Accordion
                          expanded={expanded2 === "panel1"}
                          onChange={handleChange2("panel1")}
                          sx={{ marginTop: "2%" }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography sx={{ fontWeight: "700" }}>
                              {item2.name}
                            </Typography>
                          </AccordionSummary>
                          
                        </Accordion>
                  )})}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}
