import React from 'react';
import { Grid, Typography, Button, Card, Divider, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import './footer.css';
import certificate from '../ILETS/Images/certificate.png';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : '#ecf4ff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between', display: 'flex',

  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const LearningPath = (id) => {
  console.log(id)
  const [outline, setoutline] = useState([])
  const [length, setlength] = useState()
  const [modules, setmodules] = useState()

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [expanded1, setExpanded1] = React.useState('panel1');

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };
  const handlelength = (a) => {
    setlength(a)
    console.log(modules.length)

  }
  useEffect(async () => {

    console.log(id)
    const courseDetails = async () => {
      try {
        const response = await axios({
          url: "https://backend.keewesolutions.com/ielts/courseget",
          method: "post",
          data: id
        })

        setmodules(response.data.data.Items[0].data.data)
        setoutline(response.data.data.Items[0].data.data)
        if (length != undefined) {

          setoutline(response.data.data.Items[0].data.data.slice(0, length))
        }
      } catch (error) {
        console.log(error)
      }
    }
    courseDetails()



  }, [length])

  return (
    <Grid container sx={{ bgcolor: "#ffffff", padding: '1%' }}>
      <Grid item lg={1}></Grid>
      <Grid item lg={10} xs={12} md={12} sm={12}>



        <Grid container sx={{}}>

          <Grid item xs={12} lg={7.7} md={12} sm={12} sx={{ marginTop: '16px', marginBottom: '2%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2%', marginTop: '2%' }}>
              <Typography className='gu1' sx={{ fontFamily: 'Roboto', fontSize: '25px', lineHeight: '32px', letterSpacing: '.5px', color: '#272c37', fontWeight: '700' }}>
                Learning Path
              </Typography>
              {/* <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <div style={{ padding: '5px' }}>
                  <Button className='butn' variant="outlined" sx={{ padding: '0px 8px', outline: 'none', textTransform: 'capitalize' }} onClick={() => { handlelength(1) }}>1 Month</Button>
                </div>
                <div style={{ padding: '5px' }}>
                  <Button className='butn' variant="outlined" sx={{ padding: '0px 8px', outline: 'none', textTransform: 'capitalize' }} onClick={() => { handlelength(modules.length / 4) }}>3 Month</Button>
                </div>
                <div style={{ padding: '5px' }}>
                  <Button className='butn' variant="outlined" sx={{ padding: '0px 8px', outline: 'none', textTransform: 'capitalize' }} onClick={() => { handlelength(modules.length / 2) }}>6 Months</Button>
                </div>
                <div style={{ padding: '5px' }}>
                  <Button className='butn' variant="outlined" sx={{ padding: '0px 8px', outline: 'none', textTransform: 'capitalize' }} onClick={() => { handlelength(modules.length) }}>12 months</Button>
                </div>

              </div> */}

            </div>


            {outline.map((item, index) => {

              return (
                <div style={{ border: '1px solid #e2edf9', borderRadius: '4px' }}>
                  <Accordion sx={{}} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                    <AccordionSummary aria-controls={`panel${index + 1}d-content`} id={`panel${index + 1}d-header`} sx={{ "&:hover": { backgroundColor: '#fff' } }}>
                      <Typography >{item.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.topics.map((topic, topicIndex) => {
                        return (

                          <Grid sx={{ border: '3px dotted #e2edf9', borderRadius: '4px' }}>

                            <Accordion sx={{}} expanded={expanded1 === `panel${topicIndex + 1}`} onChange={handleChange1(`panel${topicIndex + 1}`)} key={topic.id}>
                              <AccordionSummary aria-controls={`panel${topicIndex + 1}d-content`} id={`panel${topicIndex + 1}d-header`} sx={{ "&:hover": { backgroundColor: '#fff' } }}>
                                <Typography>{topic.name}</Typography>
                              </AccordionSummary>
                              {topic.subTopics && topic.subTopics.map((subTopic, subTopicIndex) => {
                                return (
                                  <AccordionDetails>
                                    <div style={{ display: 'flex', marginLeft: '8%' }}>
                                      <CircleIcon sx={{ color: '#bcd2ef', width: '0.5em', height: '0.5em', fontSize: '14px', marginRight: '3%', marginTop: '1%' }} />
                                      <Typography>
                                        {subTopic.name}
                                      </Typography>
                                    </div>
                                  </AccordionDetails>
                                )
                              })}
                            </Accordion>
                          </Grid>
                        )
                      })}

                    </AccordionDetails>
                  </Accordion>
                </div>
              )
            })}



          </Grid>
          <Grid item lg={0.3}></Grid>
          <Grid item xs={12} lg={3.5} md={12} sm={12} sx={{ marginTop: '6.5%' }}>
            <Card sx={{ marginBottom: '24px', padding: '16px 25px', boxShadow: '0px 1px 5px rgba(17,121,239,.15)', borderRadius: '4px', }}>
              <Typography sx={{ fontSize: '15px', fontWeight: '400', color: '#6c7480', lineHeight: '1.5', fontFamily: 'Roboto', }}>Contact Us</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '25px', fontWeight: '400', color: '#272c37', letterSpacing: '2px', fontFamily: 'Roboto', margin: '8px 0', lineHeight: '37px' }}>8171981719</Typography>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ color: 'black', marginleft: '2%', marginRight: '2%' }} />
                <a href='tel:+918171981719'><LocalPhoneIcon sx={{ color: '#1976d2', width: '1.2em', height: '1.2em', marginTop: '3%' }} /></a>
              </div>
            </Card>
            <Card sx={{ marginBottom: '24px', padding: '16px 25px', boxShadow: '0px 1px 5px rgba(17,121,239,.15)', borderRadius: '4px', }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#272c37', lineHeight: 'normal', fontFamily: 'Roboto', marginTop: '14px', textAlign: 'center', letterSpacing: '0.5px' }}>REQUEST MORE INFORMATION</Typography>
              <form style={{ padding: '10px', marginTop: '6px' }}>
                <TextField sx={{ marginBottom: '10px' }} fullWidth id="standard-basic" label="Name*" variant="standard" />
                <TextField sx={{ marginBottom: '10px' }} fullWidth id="standard-basic" label="Email*" variant="standard" />
                <TextField sx={{ marginBottom: '10px' }} fullWidth id="standard-basic" label="Phone no*" variant="standard" />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                  <Button variant="contained" sx={{ width: '100%' }}>Submit</Button>
                </div>

              </form>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={7.7} >
            <Typography className='gu2' sx={{ fontFamily: 'Roboto', fontSize: '25px', lineHeight: '12px', letterSpacing: '.5px', color: '#272c37', fontWeight: '700', marginBottom: '1%', marginTop: '1%' }}>
              Certificate
            </Typography>
            <Typography className='gu3' sx={{ fontFamily: 'Roboto', fontSize: '1rem', letterSpacing: '.5px', color: '#272c37', fontWeight: '400', marginBottom: '1%', marginTop: '1%' }}>
              At the End, Certificates will be issued to all the successful candidates who secure a minimum of 50% in the Final Online Assessment.
            </Typography>
            <img src={certificate} style={{ width: "100%", height: 'auto' }} alt='not found' />
          </Grid>

        </Grid>

      </Grid>
      <Grid item lg={1.5}></Grid>

    </Grid>
  )
}

export default LearningPath
