import { Grid, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import rec from './rec.png';
import rec1 from './rec1.png';
import badge from './badge.png';
import badge1 from './badge1.png';
import badge2 from './badge2.png';
import badge3 from './badge3.png';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { ConsoleView } from 'react-device-detect';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#C55524' : '#308fe8',
    },
}));


const Currentpro2 = ({ percentage}) => {
    // const navigate = useNavigate();
    console.log(percentage)

    return (
        <Grid xs={12} lg={12}>
            <Box sx={{ border: '1px solid #E3E5E7', padding: '3%', marginTop: '4%' }}>
                {/* <Typography sx={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '22px', lineHeight: '27px', color: '#000000', marginLeft: '6%' }}>
                    Current Progress
                </Typography> */}
                <Divider sx={{ marginTop: '4%' }} />
                <Box sx={{ padding: '4%', display: 'flex' }} >
                    <Grid sx={{ width: "100%" }}>
                        <Box display={"flex"} justifyContent={"space-between"} sx={{ padding: "4%" }}>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', lineHeight: '21px', color: '#C55524' }}>
                                Progress
                            </Typography>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', lineHeight: '21px', color: '#C55524' }}>
                                {percentage} %
                            </Typography>
                        </Box>
                        <Box sx={{ marginTop: '-2%', border: '2px solid #C55524', height: '20px', borderRadius: '16px', justifyContent: 'center', width: '95%' }}>
                            <BorderLinearProgress variant="determinate" value={percentage} sx={{ marginTop: '1.5%', width: '95%', marginLeft: '2%' }} />
                        </Box>
                    </Grid>
                    {/* <Grid>
                        <img src={badge} alt="" style={{ marginLeft: '4%', marginTop: '-10%' }} />
                    </Grid> */}

                </Box>
                {/* <Box sx={{ padding: '4%' }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '26px', lineHeight: '27px', color: '#C55524', justifyContent: 'center', display: 'flex', letterSpacing: '0.04em', marginTop: '-4%' }}>
                        Congratulations!
                    </Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#625F59', marginTop: "6%" }}>
                        You've earned the Course Badge! To earn more badges, complete the course or participate in the following sections.
                    </Typography>
                </Box>
                <Box sx={{ justifyContent: 'space-evenly', display: 'inline-flex' }}>
                    <Box sx={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <NavLink to={'/Incubation'} style={{textDecoration:'underline'}}>Go to Incubation</NavLink>
                        <img src={badge1} alt="" />
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', lineHeight: '100%', color: '#64748B'}}>
                            Silver Badge
                        </Typography>
                    </Box>
                    <Box sx={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <NavLink to={'/synergy'} style={{textDecoration:'underline'}}>Go to Research</NavLink>
                        <img src={badge2} alt="" />
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', lineHeight: '100%', color: '#64748B' }}>
                            Gold Badge
                        </Typography>
                    </Box>
                    <Box sx={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <NavLink to={'/Incubation'} style={{textDecoration:'underline'}}>Jobs for You</NavLink>
                        <img src={badge3} alt="" />
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', lineHeight: '100%', color: '#64748B' }}>
                            Platinum Badge
                        </Typography>
                    </Box>
                </Box> */}
            </Box>
        </Grid>
    )
}





export default Currentpro2;