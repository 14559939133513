import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button, Grid, TextField, Link, InputLabel, OutlinedInput, IconButton, InputAdornment, FormControl, Modal } from "@mui/material";
import PasswordBox from "./PasswordBox";
import img from './crest.png'
import { NavLink, useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from "axios";
import { IfPaidContext, UserContext } from "ContextFiles";
import microsoft from '../../assets/images/icons/microsoft.svg';
import apple from '../../assets/images/icons/apple.svg';
import google from '../../assets/images/icons/google.svg';
import facebook from '../../assets/images/icons/facebook.svg';
import ForgotPassword from "./ForgotPasswordEmail";
import { useLocation } from "react-router-dom";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";

export default function Login() {
  const { state } = useLocation()
  const navigate = useNavigate();
  const [user, setUser] = useState()
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const contextUser = useContext(UserContext)

  const { data, setData } = useContext(IfPaidContext)

  const [showPassword, setShowPassword] = React.useState(false);
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }
  const [url, seturl]=useState()

  const handleClick = () => {
    alert("path not set")
  }
  
  const handleSubmit = event => {
    event.preventDefault();
    axios({
      url: 'https://backend.keewesolutions.com/userIELTS/login',
      method: 'POST',
      data: {
        username: data.email,
        password: data.password
      }
    })
      .then((response) => {
        console.log(response)
        if (response.data === "UserNotConfirmedException") {
          navigate("/verify/", { state: { user: email } })
        }
        else if (response.data.accessToken !== undefined) {
          let userData = response.data
          let newUserContext = { ...contextUser }
          newUserContext.authenticated = true
          let newUser = {
            id: userData.idToken.payload['cognito:username'],
            name: userData.idToken.payload.name,
            email: userData.idToken.payload.email,
            phone: userData.idToken.payload.phone_number,
            idToken: userData.idToken.jwtToken,
            refreshToken: userData.refreshToken
          }
          newUserContext.user = newUser
          contextUser.setNewUser(newUserContext)

          let localStorageObject = JSON.stringify({
            isAuthenticated: true,
            user: newUser
          })
          localStorage.setItem("dbu.lmsStorage", localStorageObject)
          if(location.search){
            axios({
              url:"https://backend.keewesolutions.com/payment/create",
              method:"POST",
              data:{
                Email:newUser.email,
                name:newUser.name,
                UserId:newUser.id,
                Phone:newUser.phone.split(''),
                courseId:location.search.split("?")[1].split("&")[0],
                price:location.search.split("?")[1].split("&")[1]
              }
            }).then(res=>{
              console.log(res.data.data)
            seturl(res.data.data)
            console.log("payment",location.search)
            location.href=res.data.data
            })
          }
          else{
            console.log("not payment")
            navigate("/" )
          }
          
          const getData = async () => {
            await axios({
              url: "https://backend.keewesolutions.com/findIfPaid",
              method: "POST",
              data: { userId: newUser.id }
            }).then((res) => {
              console.log(res.data.Items.length)
              setData(res.data.Items)
            })
          }
          getData();
        } else {
          alert('Invalid Credentials')
        }
      })
  }

 
  const [open, setOpen] = useState(false)
  const handleForgotPasswordOpen = () => setOpen(true)
  const handleForgotPasswordClose = () => setOpen(false)

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e)=>setData({...data,email:e.target.value})}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=>setData({...data,password:e.target.value})}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              {/* <Link href="#" variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
            <Grid item>
              <Link href={`/register${location.search}`}variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );

}