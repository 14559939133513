import React, { useContext, useState } from 'react';
import logo from './../../assets/logofont.svg';
import human from './../../assets/human.svg';
import { CommonInput, CtaButton, WebcamCapture } from '../../components';
import './register.css';
import axios from 'axios'
import { ExamContext } from '../../App';

const inputField = [  'email', 'fullName'];

const Register = () => {
	const{ExamState,setExamState}=useContext(ExamContext);
	const [InputData,setInputData]=useState({});
	const register=async ()=>{
		await axios({
			method:"post",
			url:"http://localhost:5000/api/register",
			data:InputData
		}).then(res=>{
			console.log(res)
		})

	}
	return (
		<div className="user-register">
			{/* <div className="logo">
				<img src={logo} alt="aankh-logo" />
			</div> */}
			<div className="register-form">
				{/* <h1 className="title-heading">Enter Detail</h1>
				<div className="input-fields">
					{inputField.map((item) => (
						<CommonInput placeholderText={item} Field={item} InputData={InputData} setInputData={setInputData}/>
					))}
				</div> */}
				<div className="image-capture">
					{/* <img src={human} alt="human-outline" height="350px"/> */}
					<WebcamCapture />
				</div>
			</div>
		</div>
	);
};

export default Register;
