import React, { useEffect, useState } from 'react';
import Footer from './footer';
import { useLocation } from 'react-router-dom';
import TopNav from './TopNav';
import { Grid } from '@mui/material'


const Refund = () => {

    return (
        <>
            <Grid container>
                <TopNav />
                <Grid item lg={1} xs={1} md={1} sm={1}></Grid>
                <Grid item lg={10} xs={10} md={10} sm={10} sx={{}}>
                    
                    <div style={{ padding:"4 8 8 8"}} >
                       <h3 style={{ textAlign: "center" ,padding:"2%"}}>
                            Refund Policy
                        </h3>
                       <p>
                       Once the Course purchased on <a href='https://deshbhagatglobalservices.com'> www.deshbhagatglobalservices.com</a>, fees is non-refundable in nature. We do not provide refund on courses purchased to our customers.
                       <br/>
                        For queries contact us at contact@deshbhagatglobalservices.com
                       </p>
                    </div>
                </Grid>
                <Grid item lg={1} xs={1} md={1} sm={1}></Grid>
                <Footer />
            </Grid>

        </>
    )
}

export default Refund
