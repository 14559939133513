import { Grid, Typography, Box, Button, Avatar, TextField, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import React, { useEffect, useState } from "react"
// import Drone from "../images/Drone.png"
import priyaimg from "../../assets/images/studash/incubation/priyaimg.JPG"
import NFT from '../../assets/images/studash/incubation/NFT.png'
import "./Incubationcard.css"
import { NavLink } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import axios from "axios";
// import Hydroponics from '../images/Hydroponics.png';



export default function IncubationCard() {
    const [querry, setQuerry] = useState("")
    const [filter, setfilter] = React.useState("MOst Viewed");
    const [dataArr, setDataArr] = useState([])
    useEffect(() => {
        async function Ideas() {

            try {

                const response = await axios({
                    url: "https://cms.keewesolutions.com/incubation/all_ideas",
                    method: 'post',
                })
                console.log(response.data)
                console.log(typeof (response.data))
                const dataArr = response.data.Items;
                console.log(dataArr)
                setDataArr(dataArr)

            }
            catch (error) {
                console.log(error)
            }
        } Ideas()
    }, [])
    const handleChange = (event) => {
        setfilter(event.target.value);
    }

    const arr = [
        { image: NFT, name: 'NFT Loan Platform', para: 'Ideation on Non-fungible tokens performing as lending platforms.The acceptance of non-fungible assets as collaterals securities.This idea motivates to hold tokens and grants access to interest loans for lenders.', personimg: priyaimg, person: 'Priyanka' },


    ]
    return (
        <Grid container xs={12} lg={12} >
            <Grid item xs={12} >
                <Grid>
                    <Box sx={{ border: '1px solid #d6ddeb', width: '75%', margin: '0 3%' }}>
                        <Grid container item>
                            <Grid lg={1} />
                            <Grid item xs={11} lg={4} marginLeft={'10px'}>
                                <SearchIcon sx={{ fontSize: 'xx-large', color: '#25324B', marginTop: '10px' }} />
                                <TextField onChange={event => setQuerry(event.target.value)} sx={{ width: '70%' }} id="outlined-basic" label="Search Here" variant="standard" />
                            </Grid>
                            <Grid xs={0.5} lg={0.5} marginTop={'10px'} >
                                <Box sx={{ width: '0.5px', height: '40px', backgroundColor: '#202430' }} />
                            </Grid>
                            <Grid item xs={6} lg={3} marginLeft='10px'>
                                <FilterAltIcon sx={{ fontSize: 'xx-large', color: '#25324B', marginTop: "10px" }} />
                                <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={filter}
                                        onChange={handleChange}>
                                        <MenuItem value={"Most Relevant"}>Most Relevant</MenuItem>
                                        <MenuItem value={"Most Viewed'"}>Most Viewed</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={1} />

                            <Grid item xs={2} lg={1} marginTop={'10px'}>
                                <Button variant='contained' sx={{ borderRadius: '0px' }}>Search</Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography sx={{ margin: '1% 4%' }}>Filter your search by Categories and  use the search bar for refined results </Typography>
                </Grid>
            </Grid>



            <Grid item xs={12} lg={12} sx={{ marginTop: '10px', marginLeft: '0px' }}>
                <Grid lg={1} />


                <Grid className="card" item xs={12} lg={10} container sx={{
                    padding: "12px"
                }} > {dataArr.filter(item => {
                    if (querry == "") {
                        return item
                    }
                    else if (item.projectName.toLowerCase().includes(querry.toLowerCase())) {
                        return item;
                    }
                }).map((item) => {
                    console.log(item)
                    console.log(item.ideaImage)
                    return (
                        <Grid key={item.projectName} item xs={12} lg={8} marginTop={'10px'} >
                            <Grid item xs={12} lg={12} sx={{ border: '1px solid #D6DDEB', padding: "12px", marginX: "36px", marginY: "24px", boxShadow: '0px 0px 12px 1px lightgray' }}>
                                <Grid sx={{ display: 'flex', marginTop: '2px', marginBottom: '10px', marginLeft: '10px' }}>
                                    <NavLink to={'/show-details'} state={{ data: item }} style={{ textDecoration: 'none' }}><Button variant='outlined' sx={{  borderRadius: '5px' , textAlign:"left" , width:"150px"}}>Show Details</Button></NavLink>
                                    {/* <Box width={"120px"}></Box> */}
                                    <Box sx={{ marginLeft: '25%', border: '1px solid #FFB836', borderRadius: '8px', color: '#FFB836', width: '120px', height: '35px', textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingTop: '1.4%' }}> Under Progress</Box>
                                    {/* <Typography sx={{ marginLeft: '76px', display: 'flex', fontSize: '20px', color: '#001A49', width: '100px' }}>
                                            <Avatar sx={{ width: '60px', height: '60px' }} src={items.personimg} />{items.person}</Typography> */}
                                </Grid>
                                <Grid>

                                    <img src={item.ideaImage} alt='asdf' style={{ width: '100%', height: '150px' }} />
                                </Grid>
                                <Grid sx={{ display: 'flex', marginLeft: '10px' }}>
                                    <Grid>
                                        <Typography sx={{ fontSize: '16px' }}>Technology</Typography>
                                        <Typography marginTop={'1px'} sx={{ fontSize: '22px', color: '#25324B' }}><b>{item.projectName}</b></Typography>
                                    </Grid>

                                </Grid>
                                {/* <Typography marginTop={'3px'} sx={{ color: '#001A49', marginLeft: '10px', fontSize: '16px', marginRight: '10px', textAlign: 'justify' }}>
                                    {item.projectDescription}
                                </Typography> */}

                            </Grid>
                        </Grid>
                    )
                })}
                </Grid>
                <Grid lg={1} />


            </Grid>

        </Grid>
    )
} 