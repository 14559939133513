import { Typography, Grid, Button, Collapse,TextField } from "@mui/material";
import { useState } from 'react'
import { Box} from "@mui/system";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


const Faqs = () => {

    const [arr, setarr] = useState([
        { display: 'none', question: 'Are these online programs easily accessible?', answer: 'Online courses transcend time, geographic locations and other barriers to higher education. This can be helpful to those who work full time, live in remote regions or are constantly on the move.', icon: AddCircleOutlineIcon },
        { display: 'none', question: 'When can I take exams?', answer: 'The University does not have any set schedule for the exams. It is a self-paced course.', icon: AddCircleOutlineIcon },
        { display: 'none', question: 'Will a certificate be provided?', answer: 'A certificate will be issued by the university to all the successful candidates who secure a minimum of 50% in the final online assessment.', icon: AddCircleOutlineIcon },
        { display: 'none', question: 'What is the overall structure of the COURSE ? What are the Levels?', answer: 'The Course is split into three levels:-Module, Topics and Sub-Topics', icon: AddCircleOutlineIcon },
        { display: 'none', question: 'What is the language of instruction for these courses? Are they available in other regional languages?', answer: 'All our courses are taught in a mix of English and Hindi. Hence, English knowledge will not be a barrier to participate in the program.', icon: AddCircleOutlineIcon },
        { display: 'none', question: 'How / where can I ask questions or doubts related to the course content of the program?', answer: 'Each course page will have a discussion forum where learners can raise their course-related questions and interact with the course instructor or peers.', icon: AddCircleOutlineIcon }
    ])
    const [opencollapse,setopencollapse]=useState(false)
    const [readMore,setReadMore]=useState(false);
    const [Ques, setQues] = useState('')
  const [Ans , setAns]= useState('')


    return (
        <Grid conatainer lg={9} xs={11}>
            {/* <form>
                
                <Typography
                    sx={{
                        fontFamily: 'Roboto', marginTop: '20px', fontSize: '16px', fontWeight: '500', lineHeight: '24px', color: '#747070',marginLeft: '20px'
                    }}
                  >
                  Write a question:
                  </Typography>
                <TextField type={'text'}
                  value={Ques} onChange={e=>setQues(e.target.value)}
                sx={{width: '95%', marginLeft: '20px', marginTop: '10px', boxShadow: '0px 5px 5px 0px #e7e1e1'}}
                  id="outlined-basic"
                  multiline
                 variant="outlined"
                 rows={1.5}
                />
                  <Typography
                    sx={{
                        fontFamily: 'Roboto', marginTop: '20px', fontSize: '16px', fontWeight: '500', lineHeight: '24px', color: '#747070',marginLeft: '20px'
                    }}
                  >
                  Answer:
                  </Typography>
                <TextField type={'text'}
                value={Ans} onChange={e=>setAns(e.target.value)}
                sx={{width: '95%', marginLeft: '20px', marginTop: '10px', boxShadow: '0px 5px 5px 0px #e7e1e1'}}
                  id="outlined-basic"
                  multiline
                 variant="outlined"
                 rows={5}
                />
                  <Button variant="contained"  sx={{ marginLeft: '20px', marginTop: '30px'}} 
                   onClick={()=>{
                    let newarr1 = [...arr]
                    newarr1.push( {
                    
                      question: Ques,
                      answer: Ans,
                      display: 'none',
                      icon: AddCircleOutlineIcon
                    })
                    setarr(newarr1)
                  }}>
                Upload
              </Button>
            </form> */}
            <Grid item sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontFamily: 'Roboto', marginTop: '20px', fontSize: '16px', fontWeight: '700', lineHeight: '24px', color: '#000' }}>Frequently Asked Questions</Typography>
            </Grid>
            <Grid item sx={{ textAlign: 'center' }}>
                {arr.map((item, index) => {if(index>2)return null
                    return (
                        <Box key={item.question + index} sx={{ width: '95%', border: '1px solid #E3E5E7', marginLeft: '20px', marginTop: '20px', boxShadow: '0px 5px 5px 0px #e7e1e1' }}>
                            <Grid display={'flex'}>
                                <Typography sx={{ textAlign:'left', fontFamily: 'Roboto', marginTop: '10px', fontSize: '16px', fontWeight: '400', color: '#298BD0', marginLeft: '20px', width: '100%',marginBottom:'10px' }}>{item.question}</Typography>
                                <Button onClick={() => {
                                    let newStateArr = [...arr]
                                    
                                    if(newStateArr[index].display === "flex"){
                                        newStateArr[index].display = "none"
                                       
                                        newStateArr[index].icon = AddCircleOutlineIcon
                                        setarr(newStateArr)
                                        return null
                                        
                                    }
                                    else if  (newStateArr[index].display === "none"){
                                        newStateArr[index].display = "flex"
                                       
                                        newStateArr[index].icon = RemoveCircleOutlineIcon
                                        setarr(newStateArr)
                                        return null
                                    }
                                    
                                }


                                }>{<item.icon/>}</Button>
                               
                            </Grid >
                            <Typography sx={{fontWeight:'500',fontFamily: 'Roboto', textAlign:'left', marginLeft: '20px', marginTop: '2px', width: '95%',marginBottom:'10px', display: item.display,marginRight:'10px',color:'#777F8A' }}>{item.answer} </Typography>

                        </Box> )
                })}<Collapse in={opencollapse}>
                    {arr.map((item, index) => {if(index<3)return null
                    return (
                        <Box key={item.question + index} sx={{ width: '95%', border: '1px solid #E3E5E7', marginLeft: '20px', marginTop: '20px', boxShadow: '0px 5px 5px 0px #e7e1e1' }}>
                            <Grid display={'flex'}>
                                <Typography sx={{ textAlign:'left', fontFamily: 'Roboto', marginTop: '10px', fontSize: '16px', fontWeight: '400', color: '#298BD0', marginLeft: '20px', width: '100%',marginBottom:'10px' }}>{item.question}</Typography>
                                <Button onClick={() => {
                                    let newStateArr = [...arr]
                                    
                                    if(newStateArr[index].display === "flex"){
                                        newStateArr[index].display = "none"
                                       
                                        newStateArr[index].icon = AddCircleOutlineIcon
                                        setarr(newStateArr)
                                        return null
                                        
                                    }
                                    else if  (newStateArr[index].display === "none"){
                                        newStateArr[index].display = "flex"
                                       
                                        newStateArr[index].icon = RemoveCircleOutlineIcon
                                        setarr(newStateArr)
                                        return null
                                    }
                                }


                                }>{<item.icon/>}</Button>
                               
                            </Grid >
                            <Typography sx={{fontWeight:'500', fontFamily: "Roboto", textAlign:'left', marginLeft: '20px', marginTop: '2px', width: '95%',marginBottom:'10px', display: item.display,marginRight:'10px',color:'#777F8A' }}>{item.answer} </Typography>

                        </Box>

                    )
                })}</Collapse>
                <Button variant="contained" onClick={()=>{
                    setopencollapse(!opencollapse)
                    setReadMore(!readMore)
                }} sx={{marginTop:'20px' , marginBottom:"20px"}}>{!readMore ? (<>View more</>) : (<>View Less</>)}</Button>
                

            </Grid>
        </Grid>
    )
}
export default Faqs;