// assets
import {
    // Icon3dCubeSphere,
    IconCardboards,
    IconCertificate,
    IconDashboard,
    IconDatabase,
    // IconEdit,
    IconFileLike,
    // IconFileOff,
    IconInfoCircle,
    IconLayersLinked,
    // IconOmega,
    IconOutlet,
    IconBriefcase,
    // IconStepInto
} from '@tabler/icons';
// import DashIcon from '../assets/images/DashImage/DashIcon.png';
// import Telegram from '../assets/images/DashImage/Telegram.png';


// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Buy Courses',
        //     type: 'item',
        //     path: '/buycourses',
        //     icon: IconCertificate ,
        //     breadcrumbs: false
        // },
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            path: '/dashboard',
            icon: IconDatabase ,
            breadcrumbs: false
        },
        {
            id: 'util-typography',
            title: 'Latest Updates',
            type: 'item',
            path: '/latest-updates',
            icon: IconFileLike,
            breadcrumbs: false
        },
        {
            id: 'current-courses',
            title: 'Current Courses',
            type: 'item',
            path: '/current-courses',
            icon: IconCardboards,
            breadcrumbs: false
        },
        // {
        //     id: 'util-shadow',
        //     title: 'Buy Courses',
        //     type: 'item',
        //     url: '/my-open-case',
        //     breadcrumbs: false
        // },
        // {
        //     id: 'open-case',
        //     title: 'My Open Case',
        //     type: 'item',
        //     path: '/finalresume',
        //     icon: IconOutlet,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'tabler-icons',
        //     title: 'Incubation',
        //     type: 'item',
        //     path: '/Incubation',
        //     icon: IconInfoCircle,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'material-icons',
        //     title: 'Synergy',
        //     type: 'item',
        //     path: '/synergy',
        //     icon: IconLayersLinked,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'sample-page',
        //     title: 'Jobs for You',
        //     type: 'item',
        //     path: '/job-portal',
        //     icon: IconBriefcase,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'sample-page',
        //     title: 'Certificate',
        //     type: 'item',
        //     path: '/coursecertificate',
        //     icon: IconBriefcase,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;