import React, { useEffect, useState } from 'react';
import { Grid, Card, Typography, Button, } from '@mui/material';
import webdevelopment from './Images/webdevlopment.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import plumbing from './Images/plumbing.jpg';
import design from './Images/diplms in desiging.png';
import socialmedia from './Images/social media marketing.png';
import { LinkedInLoginButton } from 'react-social-login-buttons';
import { Link } from 'react-router-dom';
import dbu from '../images/dbu.png';
import './re.css';
import axios from 'axios';


const PgProgramsCards = (data) => {
     console.log(data.data)
    const newdata = data.data.split("_").reduce((con,a)=>con+" "+a)
    
    console.log(newdata.slice(1))
 
   const [courses, setcourses] = useState([])
  const course = async (category) => {
    
    await axios({
      url: "https://backend.keewesolutions.com/courseIELTS/allCourses",
      method: "post",
    }).then((response) => {
      console.log(response.data.Items)
      let arr = []
      for (let i in response.data.Items) {
        if (response.data.Items[i].category == category){
          arr.push(response.data.Items[i])
        }

      }
      console.log(arr)
      setcourses(arr)
    })
  }
  
  useEffect(() => {
    if (data != undefined) {
      if (newdata!= undefined) { 
        course(newdata.slice(1))
      }
    }         
     
  }, [data])
  window.scrollTo(0, 0)
  return (
    
    <Grid container sx={{ bgcolor: '#f8fbff', paddingTop: '64px', paddingBottom: '40px', paddingLeft: '1%', paddingRight: '1%' }}>
      <Grid item lg={1}></Grid>
      <Grid item xs={12} lg={10} md={12} sm={12} >
        <Typography className='Head' sx={{ fontFamily: 'Roboto', fontSize: '36px', fontWeight: '300', lineHeight: 'normal', letterSpacing: '1px', color: '#272c37', }}>
         {newdata.slice(1)}
        </Typography>
        
        <Grid container sx={{ marginTop: '40px' }}>
          {courses.map((row, index) => {
           return(
            
            <Grid item xs={12} lg={3} md={6} sm={6} sx={{  paddingBottom: '12px',paddingRight:'1%',paddingLeft:'1%',marginBottom:'2%' }}>
               <Link to={{
                pathname: "/coursecontent",
                search: `${row.courseId}`
              }}>
               <Card  sx={{ width: '100%', height: 'auto', boxShadow: '0 2px 5px 0 rgba(17,121,239,.15)', borderBottomRightRadius: "6px", borderBottomLeftRadius: '6px', border: '1px solid #e6ecef', bgcolor: '#fff', }}>
                

               <Card  sx={{ width: '100%', height: '485px', boxShadow: '0 2px 5px 0 rgba(17,121,239,.15)', borderBottomRightRadius: "6px", borderBottomLeftRadius: '6px', border: '1px solid #e6ecef', bgcolor: '#fff', }}>
                  <img src={"https://dbucourse.s3.amazonaws.com/"+row.courseId+".png"} alt="" style={{ width: '100%', height: '200px' }} />
                  <div style={{ marginLeft: "10px", marginRight: '10px' }}>
                    <Card sx={{ margin: '-14px 10px 0 10px', padding: '24px 20px 0 20px', borderRadius: '6px', boxShadow: 'none', position: 'relative' }}>
                      <Typography sx={{ fontSize: '14px', lineHeight: '1.44', color: '#272c37', fontWeight: '700', fontFamily: 'Roboto',textTransform:"uppercase" }}>
                        {row.title}
                      </Typography>
                      <Typography style={{ fontWeight: '500', marginBottom: '12px', color: '#51565e', fontSize: '14px', fontFamily: 'Roboto', marginTop: '24px' }}>
                        <CalendarTodayIcon sx={{ fontSize: 'large', marginRight: '5px', marginTop: '-1%', color: 'rgb(81 86 94 / 54%)' }} /> 12 month
                      </Typography>
                     
                      <img style={{ width: '120px', height: '90px',  marginBottom: '20px' }} src={dbu} alt="" />
                    </Card>
                  </div>

                </Card>
                </Card>
              </Link>

            </Grid>
           
          )})}


        </Grid>


      </Grid>
      <Grid item lg={1}></Grid>
    </Grid>
      
  )
}

export default PgProgramsCards
