import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logonew from './logonew.jpg';
import { Grid } from '@mui/material';
import './i.css';
import CloseIcon from '@mui/icons-material/Close';

    

const drawerWidth = 240;
const navItems = [{Name:'Home', Link:"https://www.deshbhagatonline.com/#/"},{Name:'Courses', Link:'https://www.deshbhagatonline.com/#/courses'}, {Name:'About', Link:'https://www.deshbhagatonline.com/#/about'}, {Name:'Contact', Link:'https://www.deshbhagatonline.com/#/contact'}];

function Nav (props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (

      <Box onClick={handleDrawerToggle} m={1} sx={{}}>
        <img src={logonew} alt="" style={{width:'70%',height:'auto',textAlign:'center',display:'block',justifyContent:'center',alignItems:'center',margin:'auto'}}/>
        <Divider />
        <List sx={{}}>
          {navItems.map((item) => (
         <a href={item.Link} style={{textDecoration:'none', color:'rgb(0 0 0 / 50%)',fontSize:'1.2rem',fontWeight:'400'}}><ListItem key={item.Name} disablePadding>
              <ListItemButton   sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.Name} />
              </ListItemButton>
            </ListItem></a>   
          ))} 
          <div style={{justifyContent:'center',display:'flex',marginTop:'2%'}}>
          <a href="https://lms.deshbhagatonline.com/" style={{textDecoration:'none'}}>
             <Button variant='contained' >Sign In</Button>
          </a>

           
          </div>
        </List>
       
      </Box>
      
    );
  
    const container = window !== undefined ? () => window().document.body : undefined;
  
    return (
      <Box sx={{ display: 'flex' ,marginBottom:'5%'}}>
        <AppBar component="nav" sx={{bgcolor:'#fff'}}>
          <Toolbar >
           
            <Grid container item xs={12} lg={12} sm={12} md={12} padding='1%'>
               <Grid item lg={5} xs={5} sm={3} md={3}>
               <img className='i' src={logonew} alt="" style={{width:'35%' }}/>
            </Grid>
           <Grid item lg={6} xs={6} sm={7} md={7}  sx={{alignSelf:'center'}}>
             <Box  sx={{ display: { xs: 'none', sm: 'block' }}}>
              {navItems.map((item) => (
                <a  href={item.Link} style={{textDecoration:'none'}}> <Button key={item.Name} sx={{ color: 'rgb(0 0 0 / 50%)',fontSize:'1.2rem',fontWeight:'400',marginTop:'1%',marginLeft:'2%','&:hover': {color:'rgb(0 0 0 / 90%)'}}}>
                 {item.Name}
                </Button></a>
              ))}
            </Box>
         
           </Grid>
           <Grid item lg={1} xs={1} sm={2} md={2} sx={{alignSelf:'center'}}>
           <a href={'https://lms.deshbhagatonline.com/'} style={{textDecoration:'none'}}>
            <Button variant='contained' sx={{ display: { xs: 'none', sm: 'block' }}}>Sign In</Button>
           </a>
            
           </Grid>
            </Grid>
           
           {!mobileOpen? <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' },color:'black' }}
            >
              <MenuIcon />
            </IconButton>:
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' },color:'black' }}
            >
              <CloseIcon/>
            </IconButton>}
            
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
            
          </Drawer>
        </Box>
        <Box component="main" sx={{ p: 0 }}>
       
          <Toolbar />
       
        </Box>
      </Box>
    );
  }
  
 
  
export default Nav
