import React, { createContext, useState } from 'react';
import {
	Register,
	Status,
	Exam
} from './containers';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


export const ExamContext=createContext();

const App = () => {
	const location=useLocation();
	const {state}=location;
	console.log(state);

	const [ExamState,setExamState]=useState(false)
	return (
		<ExamContext.Provider value={{ExamState,setExamState}}>
		<div className="App">
				<Routes>
					{ExamState?<Route path="/" element={<Exam userObj={state.userObj} courseId={state.courseId}/>}/>:<Route path="/" element={<Register />} />}
					<Route path="/status" element={<Status />} />
				</Routes>
		</div>
		</ExamContext.Provider>
	);
};

export default App;
