import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
// import { Link } from 'react-router-dom';


// import Logo from "./mrsptulogo.png";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    
    <a href="https://www.ieltsplus.deshbhagatglobalservices.com" style={{textDecoration: 'none'}}>
        <ButtonBase disableRipple component={Link} to={config.defaultPath} disabled>
            <Logo />
            <Typography sx={{ fontSize: "18px", marginLeft: "5px", color: '#616161' }}></Typography>
        </ButtonBase>
    </a>
);

export default LogoSection;
