import { useSelector } from 'react-redux';
import React, { lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicAlerts from 'Deepak/Alert';
import 'bootstrap/dist/css/bootstrap.min.css'
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CourseLayout from 'views/utilities/CurrentCourses/CourseLayout';
import Incubation from 'views/Navlink/Incubation';
import Courses from 'views/utilities/CurrentCourses/Coursess';
import HourCourses from 'views/utilities/CurrentCourses/HourCourses';
import Incubations from 'views/Navlink/Incubations';
import HomePage2 from 'views/Navlink/HomePage2';
import IdeaCard from 'views/Navlink/IdeaCard';
import RegisterCard from './formlogin/components/RegisterCard';
import { IfPaidContext, UserContext } from 'ContextFiles';
import BasicInfo from 'Namrata/components/BasicInfo';
import Page from './ILETS/ieltsplus';

// dashboard routing
const DashboardDefault = Loadable(lazy(() =>
    import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() =>
    import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() =>
    import('views/utilities/Color')));

const UtilsTypography1 = Loadable(lazy(() =>
    import('views/utilities/Typography1')));   

// sample page routing
const SamplePage = Loadable(lazy(() =>
    import('views/sample-page')));

import { Routes, Route, HashRouter as Router } from 'react-router-dom';
import ForgotPassword from 'formlogin/components/ForgotPasswordEmail';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Verification from 'formlogin/components/Verification';

// routing
import { useState, useEffect } from 'react';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Researchinfo from 'Deepak/Researchinfo';
import axios from 'axios';

import Certi from 'Certificate';


import AssesmentFinal from 'finalassesment/AssesmentFinal';
import Week from 'Week/Week';
import Progressbar from 'views/utilities/CurrentCourses/Progressbar';
import Exam from './Exam/App'
import TablerIcons from 'Coursecertificate';
import CourseContent from 'ILETS/CourseContent';
import Business from 'ILETS/business';
import Terms from 'ILETS/terms';
import Privacy from 'ILETS/privacy';
import Refund from 'ILETS/refund';



const App = () => {

    const customization = useSelector((state) => state.customization);
    const [data, setData] = useState([])
    const [user, setUser] = useState(false)
    const [authState, setAuthState] = useState({
        authenticated: false,
        user: {},
    })
    const navigate = useNavigate()
    useEffect(() => {
        const loggedInUser = localStorage.getItem("dbu.lmsStorage");
        console.log("logged",loggedInUser)
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            console.log(foundUser.user.idToken)
            axios({
                url: "https://backend.keewesolutions.com/courseIELTS/check",
                // url: 'https://backend.keewesolutions.com/check',
                method: "POST",
                data: {
                    token: foundUser.user.idToken
                }
            }).then(res => {
                console.log(res)
                if (res.data.callStatusCode === 200) {
                    let newAuthState = { ...authState }
                    newAuthState.authenticated = true
                    let newPayload = res.data.response
                    let newUser = {
                        id: newPayload['cognito:username'],
                        email: newPayload.email,
                        name: newPayload.name,
                        Phone:foundUser.user.phone,
                        idToken: foundUser.user.idToken,
                        refreshToken: foundUser.user.refreshToken.token
                    }
                    newAuthState.user = newUser
                    const getData = async()=>{
                        await axios({
                            url:"https://backend.keewesolutions.com/courseIELTS/findIfPaid",
                            method:"POST",
                            data:{userId:newAuthState.user.id}
                        }).then((res)=>{
                            console.log(res.data.Items.length)
                            setData(res.data.Items)
                        })
                    }
                    
                    getData();
                    setAuthState(newAuthState)
                   
                }
            }).catch(e => console.log(e))

        }
    }, []);

    const [company, setcompany] = useState([{}]);
   
    const [cdata, setcdata] = useState([{}])

    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <UserContext.Provider value={{ ...authState, setNewUser: setAuthState, authState }}>
                            <IfPaidContext.Provider value={{data, setData}}>
                            {authState.authenticated ?
                            //  data.length>0 || authState.user.email == "onlinecourses@mrsptu.ac.in"?
                                <Routes>
                                    <Route path="/exam" element={<Exam/>}/>
                                    <Route path='/' element={< MainLayout />}>
                                        <Route index element={< UtilsTypography />} />
                                        <Route path='/alert' element={< BasicAlerts />} />
                                        <Route path='get_certificate' element={<Certi />} />
                                        <Route path='/dashboard' element={< DashboardDefault />} />
                                        <Route path='/basicinfo' element={<BasicInfo />} />
                                        <Route path='/current-courses' element={< UtilsTypography />} />
                                        <Route path='/newcoursesadded' element={< UtilsTypography1 />} />
                                        <Route path='/progress' element={< Progressbar />} />
                                        <Route path='latest-updates' element={< UtilsColor />} />
                                        <Route path='/CourseLAnding' element={< CourseLayout />} />
                                        <Route path='/Incubation' element={<Incubation />} />
                                        <Route path='/CourseLAnding/Coursess' element={< Courses />} />
                                        <Route path='/Incubations' element={<Incubations />} />
                                        <Route path='/submitted-ideas' element={<HomePage2 />} />
                                        <Route path='/show-details' element={<IdeaCard />} />
                                        <Route path='/researchprofile' element={<Researchinfo />} />                                      
                                        <Route path="assesment/" element={<AssesmentFinal />} />
                                        <Route path='/certificate' element={<Certi/>}/>
                                        <Route path='/week' element={<Week/>}/>
                                        <Route path='/coursecertificate' element={<TablerIcons/>}/>
                                    </Route>
                                    <Route path="/coursecontent" element={<CourseContent/>} />
                                    <Route path="/" element={<Page/>} />
                                </Routes>                                  
                                : (
                                    <>
                                        <Routes>
                                            <Route path="/" element={<Page/>} />
                                            <Route path='/terms' element={<Terms/>}/>
                                            <Route path='/privacypolicy' element={<Privacy/>}/>
                                            <Route path='/refund-policy' element={<Refund/>}/>
                                            <Route path="/coursecontent" element={<CourseContent/>} />
                                            <Route path="/cat" element={<Business/>} />
                                            <Route path="register/" element={<RegisterCard id={0} search={location.search}/>} />                              
                                            <Route path="register/step2/coursecontent" element={<RegisterCard id={2} search={location.search}/>} /> 
                                            <Route path="register/step2/" element={<RegisterCard id={2} search={location.search} state={location.state}/>} />         
                                            <Route path="register/coursecontent" element={<RegisterCard id={0} search={location.search} />} />
                                            <Route path="/login" element={<RegisterCard id={1} search={location.search} />} />
                                            <Route path="login/coursecontent" element={<RegisterCard id={1} search={location.search} />} />
                                            <Route path="verify/" element={<Verification />} />
                                            <Route path="forgotpassword/" element={<ForgotPassword />} />
                                            <Route path='/certificate' element={<Certi/>}/>
                                            <Route path='/week' element={<Week/>}/>
                                        </Routes>
                                    </>
                                    )
                            }
                            </IfPaidContext.Provider>
                        </UserContext.Provider>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
};

export default App;

