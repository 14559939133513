import React, { useEffect, useState } from "react";
import Nav1 from "./Navbar";
import Footer from "./footer";
import PgProgramsCards from "./categorywiseprograms";
import TopNav from "./TopNav";
import { Grid } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

const Business=()=>{
    
    const location = useLocation()
  
  const category=location.search
 
 
   console.log(location.search)
   
   

    return(
        <>
        <Grid container>
        <TopNav/>
        <PgProgramsCards  data={category}/>
        <Footer/>
        </Grid>
        </>
    )
}
export default Business;


 