import React, { useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import imgs from './Previews.jpg';
import './Stage5.css';
import { TextareaAutosize } from '@mui/material';
import { boxSizing, height } from '@mui/system';
import Stage2N from './Stage2N';
import idea from '../../assets/images/icons/idea.png'
import { ideaArray } from './Context';
import { formContext } from "./Context";
import { stepNumber } from "./Context";
import generateKey from './generateKey';




function FormN({setShowOutlineForm}, pic) {

    const formData = useContext(formContext)
    const counter = React.useContext(stepNumber)
    const [ideaImage, setIdeaImage] = useState(null)
    const {data, setData} = React.useContext(ideaArray)

    // const handleNext = () => {
    //     if (category.length != 0 &&
    //         subCategory.length != 0 &&
    //         idea.length != 0 &&
    //         project.length != 0 &&
    //         courseImageFile.length !=0) {
    
    //         counter.increment()
    //         let newI = {
    //             ...ideaContext, data: [{
    //                 name: "Module 1",
    //                 id: v4(),
    //                 topics: [
    
    //                 ]
    //             }]
    //         }
    //         newI.category = category
    //         newI.subCategory = subCategory
    //         newI.idea = idea
    //         newI.project = project
    //         ideaContext.setIdeaState(newI)
    //         setShowOutlineForm(false)
    //         counter.increment()
    //         console.log(ideaContext)
    //     } else {
    //         alert("please complete all the fields")
    //     }
    // }

    const handleUpload = (event) => {
        // let newId = generateKey()
        // let newFileName = newId + file.name.substring(file.name.lastIndexOf('.'))

        // let newFile = new File([file], newFileName, { type: file.type })

        let file = event.target.files[0];
    let reader = new FileReader();

    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png"];
    // if (!allowedFileTypes.includes(fileExtension)) {
    //   window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
    //   return false;
    // } else if (file.size > 1e6) {
    //   window.alert("Please upload a file smaller than 1 MB");
    //   return false;
    // } else {
    //   console.log(file);
    //   reader.onload = function (e) {
        
    //     setData({...data,ideaImage:e.target.result});
    //   };
      
    //   reader.readAsDataURL(event.target.files[0]);
    // }
    reader.readAsDataURL(event.target.files[0]);

        // let newContext = {...data, ideaId: newId}
        // console.log(newContext)
        // console.log(newFileName + newContext)
        //  setData(newContext)
        // setData({...newContext})
        // console.log(data)
        // formData.set("ideaImage" ,newFile)
    }


    // const [category, setCategory] = useState('')
    // const [subCategory, setSubCategory] = useState('')
    // const [idea, setIdea] = useState('')
    // const [project, setProject] = useState('')
    const [imageUrl, setImageUrl] = useState(null)
    const [courseImageFile, setCourseImageFile] = useState('')


    // const BootstrapInput = styled(InputBase)(({ theme }) => ({
    //     'label + &': {
    //         marginTop: theme.spacing(3)
    //     },
    //     '& .MuiInputBase-input': {
    //         borderRadius: 4,
    //         position: 'relative',
    //         // backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    //         border: '1px solid black',
    //         fontSize: 16,
    //         width: '100%',
    //         padding: '10px 10px',
    //         transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    //         // Use the system font instead of the default Roboto font.
    //         fontFamily: [
    //             '-apple-system',
    //             'BlinkMacSystemFont',
    //             '"Segoe UI"',
    //             'Roboto',
    //             '"Helvetica Neue"',
    //             'Arial',
    //             'sans-serif',
    //             '"Apple Color Emoji"',
    //             '"Segoe UI Emoji"',
    //             '"Segoe UI Symbol"'
    //         ].join(','),
    //         '&:focus': {
    //             boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //             borderColor: theme.palette.primary.main
    //         }
    //     }
    // }));

    // const BootstrapInputTextArea = styled(TextareaAutosize)(({ theme }) => ({
    //     'label + &': {
    //         marginTop: theme.spacing(3)
    //     },
    //     '& .MuiInputBase-input': {
    //         borderRadius: 4,
    //         position: 'relative',
    //         backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    //         border: '1px solid #ced4da',
    //         fontSize: 16,
    //         width: '100%',
    //         padding: '10px 10px',
    //         transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
    //     }.join(','),
    //     '&:focus': {
    //         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //         borderColor: theme.palette.primary.main
    //     }
    // }));

    return (
        <Grid container>

                <Grid lg={12} xs={11}sx={{margin:'0 0 0 10%'}}>  
                        <Grid lg={7} xs={11}sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <FormControl fullWidth variant="standard">
                                <Typography fontSize={"16px"} float={'left'} textAlign={'initial'}>
                                    Category*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        setData({...data,category:e.target.value})
                                    }}
                                    sx={{  backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                    // margin="dense"
                                    variant="outlined"
                                    label="eg: Information Technology"
                                    id="Title"
                                    value={data.category}
                                />
                            </FormControl>
                        </Grid>
                        <Grid lg={7} xs={11}>
                            <FormControl fullWidth variant="standard">
                                <Typography fontSize={"16px"} textAlign={'initial'}>
                                    Sub-category/Domain*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        // setSubCategory(e.target.value)
                                        setData({...data,subCategory:e.target.value})
                                    }}
                                    sx={{ backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                    // margin="dense"
                                    variant="outlined"
                                    label="eg: Ed-Tech"
                                    id="Title"
                                    value={data.subCategory}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid lg={7} xs={11}>
                            <FormControl fullWidth variant="standard">
                                <Typography fontSize={"16px"} textAlign={'initial'}>
                                    Idea Name*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        // setIdea(e.target.value)
                                        setData({...data,projectName:e.target.value})
                                    }}
                                    sx={{ backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                    // margin="dense"
                                    variant="outlined"
                                    label="eg: Online Education"
                                    id="Title"
                                    value={data.projectName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid lg={7} xs={11}>
                            <FormControl fullWidth varient="standard">
                                <Typography fontSize={"16px"} textAlign={'initial'}>
                                    Project Details*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        // setProject(e.target.value)
                                        setData({...data,projectDescription:e.target.value})
                                    }}
                                    sx={{ backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                  
                                    multiline
                                    rows="3"
                                    margin="dense"
                                    variant="outlined"
                                    label="Write about the Project in detail"
                                    id=" Description"
                                    value={data.projectDescription}
                                />
                            </FormControl>
                        </Grid>
                        
                        <div>
                            <Typography fontSize={"16px"} sx={{ marginBottom: "1%", textAlign: 'match-parent' }} >
                                Upload Thumbnail*
                            </Typography>

                            {
                                (data.ideaImage === null) || (data.ideaImage === undefined) ?
                                    (
                                        <img src={imgs}
                                            alt=""
                                            style={{
                                                width: "250px",
                                                height:"200px",
                                                objectFit: "contain"
                                            }} />
                                    )
                                    :
                                    (
                                        <img src={imageUrl}
                                            alt=""
                                            style={{
                                                width: "250px",
                                                height:"200px",
                                                objectFit: "contain"
                                            }} />
                                    )
                            }
                        </div>
                        <div>
                            <label>
                            <input accept="image/*" type="file" style={{
                                display: 'none',
                                backgroundColor:'#fffdfd'
                            }}
                            onChange={e=>{
                                setImageUrl(URL.createObjectURL(e.target.files[0]))
                                // setCourseImageFile(e.target.files[0])
                                setData({...data,ideaImage:e.target.files[0]})
                                handleUpload(e)
                            }}
                            
                            />
                            <Button
                        
                            onClick={() => {
                                console.log(data.imageUrl)
                                
                            }}  

                                // onClick={handleNext}
                                variant="contained"
                                // fontSize="large"
                                disabled={courseImageFile == null ? true : false}
                                sx={{
                                    marginTop: "10px"
                                }}
                                // variant="contained"
                                component="span"

                            >
                                Upload

                            </Button>
                            </label>
                        </div>
                    </Grid>
              
            </Grid>
        
    );
}

export default FormN;