import * as React from 'react';
import './Card.css';
import { styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import bg1 from '../../../assets/images/studash/bg1.png';
import { NavLink } from 'react-router-dom';
import { Button, Grid, Card } from '@mui/material';
import { ImageAspectRatioOutlined } from '@mui/icons-material';
import logo from '../../../formlogin/components/DBU.jpg';
import axios from 'axios';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

export default function RecipeReviewCard({ courseDetails, data, id }) {
    // console.log(props)
    console.log(courseDetails)
    let { title, date, description, image } = courseDetails;
    console.log(courseDetails)
    const [expanded, setExpanded] = React.useState(false);


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    

    return (
        <Grid  container sx={{ maxWidth: "300px", minHeight: "300px", margin: '1%', boxShadow: '5px 5px 5px 7px #E5E5E5', borderRadius: '12px' }}>
           
            <Card sx={{width:"100%"}}>
                
                <CardMedia className='yourcourse' component="img" height="170" image={"https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + id + '.png'} alt="courseImage" sx={{ borderRadius: '10px' }} />
                <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                <CardHeader
                    // subheader={title}
                    action={<IconButton aria-label="settings"></IconButton>}
                    style={{ padding: '2% 5% 2% 3%' ,margin:"5% 5% 5% 0%", fontSize: '16px' }}
                    avatar={
                        <Avatar sx={{ backgroundColor: '#000', height:'40px', width: '40px' }} aria-label="recipe" elementStyle={{ padding: '%' }}>
                            <img src={logo} alt='AP' style={{width: '110%'}}></img>
                        </Avatar>
                    }
                    title="DBU"
                />

                <CardActions disableSpacing>
                    <NavLink to={'/CourseLAnding/Coursess'} 
                    state={{
                        details: courseDetails,
                        courseContent: data,
                        courseId: id

                    }} style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className='btn' sx={{borderRadius:"16px"}}>
                            <Typography sx={{fontSize: '12px'}}>Start Learning</Typography>
                        </Button>
                    </NavLink>
                </CardActions>
                </div>
                {/* <CardContent> */}
                    <Typography gutterBottom variant="h4" component="div" sx={{color: '#918d8d', padding: '0 0 0 3%'}}>
                    {title}
                    </Typography>
                {/* </CardContent> */}
            </Card>
        </Grid>
    );
}
