import * as React from 'react';
import './Card.css';
import { styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import bg1 from '../../../assets/images/studash/bg1.png';
import { NavLink } from 'react-router-dom';
import { Button, Grid, Card } from '@mui/material';
import { ImageAspectRatioOutlined } from '@mui/icons-material';
import logo from '../../../layout/MainLayout/LogoSection/mrsptulogo.png';
import Currentpro2 from 'Rohan/Currentpro2';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

export default function RecipeReviewCard({ courseDetails, data, id }) {
    // console.log(props)
    console.log(courseDetails)
    let { title, date, description, image } = courseDetails;
    console.log(courseDetails)
    const [expanded, setExpanded] = React.useState(false);

    const [progressData, setProgressData] = useState(null)
    const [percentage, setPercentage] = useState(0)


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


   

    useEffect( () => {

        const progressFunction = async () => {
            const details = await axios({
                url: "https://cms.keewesolutions.com/getrecord",
                data: {
                    'courseId': courseDetails.id,
                    'userId': JSON.parse(localStorage['dbu.lmsStorage']).user.id
                },
                method: "POST"
            })
            console.log(details.data.response.Item)
            //alert(details.data.response.Item)
            setProgressData(details.data.response.Item)
        }



        progressFunction()
       
            console.log("here")
       
    }, [])

    useEffect(() => {
        async function configureProgress(progressData) {
            if (progressData != null) {
                console.log(progressData)
                const { progressRecord } = progressData
                console.log(progressRecord)
                const len = Object.keys(progressRecord).length
                // const ones = 0
                const valArray = Object.values(progressRecord)
                console.log(valArray)
                const ones = valArray.filter(val => val === 1).length
                setPercentage(() => {
                    console.log(ones)
                    console.log(len)
                    const ans = Math.round((ones / len) * 100)
                    return parseFloat(ans).toFixed(2)
                })
               
        }
    }
        configureProgress(progressData)

    }, [progressData])

    return (
        <Grid sx={{ maxWidth: "300px", minHeight: "300px", margin: '1%', boxShadow: '5px 5px 5px #E5E5E5', borderRadius: '12px' }}>
            <Card >
                <CardMedia className='yourcourse' component="img" height="170" image={"https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + id + '.png'} alt="courseImage" sx={{ borderRadius: '10px' }} />
                <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                <CardHeader
                    // subheader={title}
                    action={<IconButton aria-label="settings"></IconButton>}
                    style={{ padding: '2% 5% 2% 0%' ,margin:"5% 5% 5% 0%", fontSize: '16px' }}
                    avatar={
                        <Avatar sx={{ backgroundColor: '#000', height:'44px', width: '44px' }} aria-label="recipe" elementStyle={{ padding: '%' }}>
                            <img src={logo} alt='AP' style={{width: '125%'}}></img>
                        </Avatar>
                    }
                    title="MRSPTU"
                />

                <CardActions disableSpacing>
                    <NavLink to={'/CourseLAnding/Coursess'} state={{
                        details: courseDetails,
                        courseContent: data,
                        courseId: id

                    }} style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className='btn' sx={{borderRadius:"16px"}}>
                            <Typography sx={{fontSize: '12px'}}>Continue Learning</Typography>
                        </Button>
                    </NavLink>
                </CardActions>
                </div>
                {/* <CardContent> */}
                    <Typography gutterBottom variant="h4" component="div" sx={{color: '#918d8d', padding: '0 0 0 3%'}}>
                    {title}
                    </Typography>
                    <Currentpro2 percentage={percentage} />
                {/* </CardContent> */}
            </Card>
        </Grid>
    );
}
