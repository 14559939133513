import React from 'react';
import { Grid, Typography } from "@mui/material";
import v1 from './Images/v1.png';
import v2 from './Images/v2.png';
import v3 from './Images/v3.png';
import v4 from './Images/v4.png';
import certi from './Images/certi.png';
import credit from './Images/credit.png';
import employ from './Images/employ.png';
import gap from './Images/gap.png';
import job from './Images/job.png';
import skill from './Images/skill.png';
import visa from './Images/visa.png';
import career from './Images/career.png';



import './re.css';

const Experience = () => {
    const arr =[
        {
            pic:certi,
            h1:"Certified Foundation Courses",
            h2:"UGC Approved: IELTS Plus Foundation Courses - Certified & Trusted"
        },
        {
            pic:job,
            h1:"Part Time Jobs",
            h2:"Part time job opportunities after complition of course"
        },
        {
            pic:gap,
            h1:"Cover Gaps",
            h2:"Fill gaps post course completion, boosting your skills and knowledge"
        },
        {
            pic:skill,
            h1:"Skill Enhancement",
            h2:"Courses for skill enhancement: Unlock your potential and excel"
        },
        {
            pic:credit,
            h1:"Credit Transfer to Colleges",
            h2:"Seamless credit transfer to foreign colleges, expanding educational horizons"
        },
        {
            pic:visa,
            h1:"Increased Visa Prospect",
            h2:"Enhanced visa prospects for better global opportunities"
        },
        {
            pic:employ,
            h1:"Employment",
            h2:"Abundant employment opportunities for course graduates"
        },
        {
            pic:career,
            h1:"Career Advancement",
            h2:"Prepare yourself for a successful career path with a strong educational foundation"
        }
    ]
  return (
   <Grid container sx={{bgcolor:'#fff',padding:'2%'}}>
   <Grid item lg={1}></Grid>
   <Grid item lg={10} xs={12} md={12} sm={12} sx={{marginTop:'48px',marginBottom:'48px'}}>
      <Typography className='m4' sx={{fontSize:'28px',fontWeight:'500',color:'#272c37',lineHeight:'normal',fontFamily:'Roboto'}}>
      Benefits
      </Typography>
      {/* <Typography className='m5' sx={{marginTop:'12px',fontSize:'34px',fontWeight:'300',color:'#272c37',lineHeight:'normal',fontFamily:'Roboto'}}>
      Lorem Lypsum
      </Typography> */}
      <Grid container className='card1' >
      {arr.map((row, index) => (
                                 <Grid item xs={12} lg={3} sm={6} md={6} sx={{marginTop:'48px',paddingRight:'2%'}}>
         <img src={row.pic} alt="" className='m1' />
         <Typography className='m2' sx={{marginTop:'30px',fontSize:'18px',fontWeight:'700',color:'#272c37',lineHeight:'1.5',fontFamily:'Roboto'}}>
         {row.h1}
      </Typography>
      <Typography className='m3' sx={{marginTop:'14px',fontSize:'16px',fontWeight:'normal',color:'#6c7480',lineHeight:'1.43',fontFamily:'Roboto'}}>
      {row.h2}
      </Typography>
      </Grid> 
     ))}
          
      </Grid>
   
   </Grid>
   <Grid item lg={1}></Grid>

   </Grid>
  )
}

export default Experience
