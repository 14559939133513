import React, { useEffect, useState } from 'react'
import logo from './mrsptu-logo.png'
import axios from 'axios';

function loadScript(src) {
    
   
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        }
        script.onerror = () => {
            resolve(false);
        }
        document.body.appendChild(script);
    })
}


async function DisplayRazorpay(studentId, name, email, phone, courseId) {
    console.log(studentId)
    // const {pay,setPay}=useContext(paymentContext);

    // const [courseName, setCourseName] = useState("")
    var courseName = "";
    
    let paymentData = { orderId: "", amount: "", status: "" }
    const response = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    
    if (!response) {
        alert("razorpay server down");
        return
    }

    const nameofcourse=await axios({
        url:"https://cms.keewesolutions.com/user/coursename",
        method:"post",
        data:{id:courseId}
    }).then((res)=>{console.log(res.data.Item.courseDetails.title)
        courseName = res.data.Item.courseDetails.title
    })

    const resp = await axios({
        url:'https://cms.keewesolutions.com/payment/create',
        method: 'POST',
        data:{studentId:studentId,
            courseId:courseId} })
        const data=resp.data;
    paymentData.orderId = data.order.id;
    paymentData.amount = data.order.amount;
    console.log(paymentData);
    const options = {
        "key": 'rzp_live_eSL8NGLcv76EhG',
        "currency": "INR",
        "name": "MRSPTU Online",
        "description": "fees Transaction",
        "image": logo,
        "order_id": data.order.id,
        "handler": async function (response) {
            const opts = {
                "paymentId": response.razorpay_payment_id,
                "orderId": response.razorpay_order_id,
                "signature": response.razorpay_signature
            };
            const resp = await fetch('https://cms.keewesolutions.com/payment/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(opts)
            }).then(res => res.json()).then((res) => { return res }).catch(e => console.log(e));
            console.log(resp)
            if (resp.signatureIsValid == 'true') {
                console.log("1");
                const response = await  axios({
                    method: "post",
                    url: "https://cms.keewesolutions.com/payment/paymentTable",
                    data: {
                        'studentId': studentId,
                        'courseId': courseId,
                        'orderId': paymentData.orderId,
                        'amount': paymentData.amount,
                        'paymentStatus': "success"
                    }
                })
                console.log("printing response")
                console.log(response)
                if (response.status === 200) {
                    await axios({
                        url: 'https://backend.keewesolutions.com/weekly/getWeeklyRecord',
                        method: "POST",
                        data: {
                            courseId: courseId,
                            userId: studentId
                        }
                    })
                    await axios({
                        url:'https://cms.keewesolutions.com/mailer/coursebought',
                        method:"post",
                        data: {email:email,
                        courseName:courseName}
                    })
                    await axios({
                        method: "post",
                        url: "https://cms.keewesolutions.com/updaterecordnew",
                        data: {
                            'userId': studentId,
                            'courseId': courseId,
                        }
                    }).then(res => {
                        
                        console.log("inside updaterecord")
                        console.log(res.data)
                    }).catch(e => console.log(e))
                    location.reload(true);

                }
                paymentData.status = "success"
                console.log("2");
            }
            else {
                console.log("fail")
                paymentData.status = "fail"
            }
            
        
        },
        "prefill": {
            "name": name,
            "email": email,
            "contact": phone
        },
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#3399cc"
        }
    };

    const payment = new window.Razorpay(options);
    payment.open();
}

export default DisplayRazorpay;
