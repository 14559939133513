import { Box, IconButton, Modal } from "@mui/material";
import React, { useContext, useState, createContext } from "react";
// import { Box } from "react-feather";
import Webcam from "react-webcam";
import imgss from "../components/Preview.jpg"
import Avatar from '@mui/material/Avatar';
import {
    Typography,
    Button,
    Grid,
    TextField,
    Link,
    Select,
    MenuItem,
    InputLabel,
    Paper
} from "@mui/material";
// import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import VectorNew from "../components/VectorNew.png"
import tick from "./tick.png"

import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
// import Select from '@mui/material/Select';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Supportcenter from "./Supportcenter";
import { useAlert } from "react-alert";
import { UserContext } from "ContextFiles";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};
export const Address = createContext();

const WebcamCapture = () => {

    const contextUser = useContext(UserContext)

    const alert = useAlert();

    const location = useLocation();
    console.log(location)
    const state = location.state;
    const navigate = useNavigate();
    const paperStyle = { padding: 20, height: '30vh', width: "max-content", margin: "200px auto" }
    const [code, setCode] = useState('')
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => {
        setOpen1(false)
        navigate({ pathname: "/login/", search: location.search });
    };

    const [aadharImg, setAadharImg] = useState(null)
    const [aadharFile, setAadharFile] = useState("")

    const [data, setdata] = useState(state)
    const webcamRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc)
            setdata({ ...data, ImageUrl: imageSrc })
            handleClose()
        },
        [webcamRef]
    );
    const handleUpload = event => {
        let file = event.target.files[0];
        setAadharFile(file)
        
        let reader = new FileReader();
    
          console.log(file);
          reader.onload = function (e) {
            setAadharImg(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
      };
    const handleback = () => {
        navigate("/register")
    }

    const onSubmit = (event) => {
        console.log(data)
        event.preventDefault();

        if (data.emailExists == false && data.usernameExists == false) {
            axios({
                url: 'https://cms.keewesolutions.com/user/signup',
                method: 'POST',
                data: {
                    name: data.name,
                    email: data.email,
                    username: data.username,
                    password: data.password,
                    phone: '+91' + data.phone,
                    country: "India"
                }
            })
                .then(
                    (response) => {

                        console.log(response)
                        if (response.data === 'UsernameExistsException') {
                            window.alert("Public Username is not available. please change and retry registering")
                            setdata({
                                name: "",
                                email: "",
                                username: "",
                                phone: "",
                                country: "",
                                password: "",
                                showPassword: false
                            })
                            
                            return;
                        }
                        else if (response.data === 'CodeDeliveryFailureException') {
                            window.alert("Error while Registering - try again!")
                            setdata({
                                name: "",
                                email: "",
                                username: "",
                                phone: "",
                                country: "",
                                password: "",
                                showPassword: false
                            })
                        } else {
                            console.log(data)
                            let formdata = new FormData();
                            formdata.append('email', data.email)
                            formdata.append('aadharFile',aadharFile)

                           
                            axios({
                                url: 'https://backend.keewesolutions.com/user/putUserData',
                                method: 'POST',
                                data: data
                            }).then((res) => {
                                console.log(res)
                                // handleOpen1()

                                axios({
                                    url: 'https://backend.keewesolutions.com/user/putAadharImg',
                                    method: 'POST',
                                    data: formdata
                                }).then((res) => {
                                    console.log(res)})
    

                                axios({
                                    url: 'https://backend.keewesolutions.com/user/login',
                                    method: 'POST',
                                    data: {
                                        username: data.username,
                                        password: data.password
                                    }
                                })
                                    .then((response) => {
                                        console.log(response)
                                        if (response.data.accessToken !== undefined) {
                                            let userData = response.data
                                            let newUserContext = { ...contextUser }
                                            newUserContext.authenticated = true
                                            let newUser = {
                                                id: userData.idToken.payload['cognito:username'],
                                                name: userData.idToken.payload.name,
                                                email: userData.idToken.payload.email,
                                                idToken: userData.idToken.jwtToken,
                                                refreshToken: userData.refreshToken
                                            }
                                            newUserContext.user = newUser
                                            contextUser.setNewUser(newUserContext)

                                            let localStorageObject = JSON.stringify({
                                                isAuthenticated: true,
                                                user: newUser
                                            })
                                            localStorage.setItem("dbu.lmsStorage", localStorageObject)
                                            alert.show("Successfully Logged In")
                                            navigate({ pathname: "/", search: location.search })
                                        }
                                        })
                                        // navigate({ pathname: "/login/", search: location.search })
                                    })

                            }
                    })
            //   },[]);

            // console.log(user)
        } else if (data.usernameExists == true) {
            window.alert("please Change Username")
        }
        else {
            window.alert("please Change email address")
        }
    }



    return (
        <div style={{ padding: "15px" }}>
            <div>
                {/* <button onClick={handleOpen}>Open modal</button> */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {/* <div  style={{ position: "absolute", top: "5%", right: "5%", cursor: "pointer" }}>             
                        <IconButton  ><ClearOutlinedIcon style={{cursor:"pointer"}} /></IconButton>
                        </div> */}
                        <Webcam
                            audio={false}
                            height={400}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width="auto"
                            videoConstraints={videoConstraints}
                        />
                        <Button onClick={capture} style={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px", color: "white" }} >Capture photo</Button>
                    </Box>
                </Modal>
            </div>

            <div >
                {data.ImageUrl != "" ? <><img
                    alt=""
                    // onClick={handleOpen}
                    src={data.ImageUrl}
                    // placeholder="qwer"
                    style={{ width: 240, height: 120 }}
                />
                    <br />
                    <Button variant="contained" sx={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px" }}
                        onClick={handleOpen} >Retake Photo</Button>
                </>
                    : <Button variant="contained" sx={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px" }}
                        onClick={handleOpen} >Upload Live Photo</Button>}
                {/* <img style={{width:"120px",height:"120px"}} src={data} alt="" /> */}
            </div>

            <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px", marginTop: "10px" }} >
                <TextField
                    fullWidth
                    label="Aadhar Card Number "
                    id="fullWidth"
                    value={data.aadhar}
                    onChange={event => { setdata({ ...data, aadhar: event.target.value.match(/^[0-9]*/) }) }}
                />
                {/* <p>{formErrors.username}</p> */}
            </Grid>
            <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px", marginTop: "10px" }} >
                <Grid>

                    {
                        aadharImg == null ?
                            (
                                <img src={imgss}
                                    alt="asdf"
                                    style={{
                                        width: "300px",
                                        height: "200px",
                                        objectFit: "contain"
                                    }} />
                            )
                            :
                            (
                                <img src={aadharImg}
                                    alt="asdf"
                                    style={{
                                        width: "300px",
                                        height: "200px",
                                        objectFit: "contain"
                                    }} />
                            )
                    }
                </Grid>
                <Grid>
                    <label>
                        <input accept="image/*" type="file" style={{
                            display: 'none'
                        }}
                            onChange={e => {
                                // setdata({...data,aadharImg:})
                                // setImageUrl(URL.createObjectURL(e.target.files[0]))
                                // setCourseImageFile(e.target.files[0])
                                handleUpload(e)
                            }}

                        />
                        <Button

                            onClick={() => {
                                console.log(data)

                            }}
                            variant="contained"
                            sx={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px" }}


                            // variant="contained"
                            component="span"

                        >
                            Upload Aadhar Card

                        </Button>
                    </label>
                </Grid>
            </Grid>


            <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }} >
                <TextField
                    fullWidth
                    label="Father's Name"
                    id="fullWidth"
                    value={data.fathername}
                    onChange={event => { setdata({ ...data, fathername: event.target.value }) }}
                />
                {/* <p>{formErrors.username}</p> */}
            </Grid>
            <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }} >
                <TextField
                    fullWidth
                    label="Mother's Name"
                    id="fullWidth"
                    value={data.mothername}
                    onChange={event => { setdata({ ...data, mothername: event.target.value }) }}
                />
                {/* <p>{formErrors.username}</p> */}
            </Grid>
            <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }} >
                <TextField
                    fullWidth
                    label="Last Qualification"
                    id="fullWidth"
                    value={data.qualification}
                    onChange={event => { setdata({ ...data, qualification: event.target.value }) }}
                />
                {/* <p>{formErrors.username}</p> */}
            </Grid>

            <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }} >

                <Supportcenter data={data} setdata={setdata} />
            </Grid>
            <Grid>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        console.log(data)
                        if (data.ImageUrl == "") {

                            alert.show("please click your live photo");
                        }
                        else if (data.aadhar == "") {
                            alert.show("please enter aadhar card number");
                        }
                        else if (data.aadhar[0].length != 12) {
                            alert.show("please enter valid aadhar card number")
                        }
                        else if (data.fathername == "") {
                            alert.show("please enter fathername");
                        }

                        else if (data.mothername == "") {
                            alert.show("please enter mothername");
                        }
                        else if (data.qualification == "") {
                            alert.show("please enter last qualification");
                        }
                        else if(aadharFile == "" || aadharFile == undefined){
                            alert.show("Please Upload Aadhar Photo");
                        }
                        else {
                            return onSubmit(e)

                        }
                    }}
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px" }}
                >
                    Submit
                </Button>

                <Button
                    onClick={handleback}
                    variant="contained"
                    sx={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px", marginLeft: "1rem" }}
                >
                    Back
                </Button>
            </Grid>

            <Modal
                style={{ height: "50%" }}
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={10} style={paperStyle} >
                    <Grid align='center' >


                        <div align="center">
                            <img src={tick} alt="iamage" style={{ width: "20%" }} />
                        </div>
                        <h2>CONGRATULATIONS!</h2>
                        <h2>You have Successfully Registered</h2>
                        <Typography onClick={() => { navigate({ pathname: "/login/", search: location.search }) }} style={{ color: "#06213F", cursor: "pointer" }}>Go To Login</Typography>
                    </Grid>

                </Paper>
            </Modal>

        </div>
    );
};

export default WebcamCapture;