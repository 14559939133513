import { Button, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import LatestUpdates from './AboutCourses';
// import BasicCard from './CourseCard';
import About from './CourseDetails/About';
import Faqs from '../../../Deepak/Faqs';
import Instructor from './CourseDetails/Instructor';
import Progress from '../../../assets/images/Courses/Progress.png'
import './CourseLAyout.css';
import Project from './Project';
import Comp from 'Rohan/Currentpro';
import Reviews from 'Rohan/Comp1';
import CurrentLanding from './CurrentLanding';
import Currentpro from 'Rohan/Currentpro';
import Discussions from './CourseDetails/Discussions';
import axios from 'axios';

const CourseLayout = () => {
    const location = useLocation()
    console.log(location)
    const [value, setValue] = useState(0);
    // useEffect(async ()=>{
    //     try {
    //         const imgCall = await axios({
    //             url: "https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + location.state.courseId + '.png'
    //         })
    //     } catch (error) {

    //     }
    // })
    return (
        <>
            <Grid className="bgiaxmg" sx={{ minHeight: '347px', minWidth: '100%', border: '0px', borderRadius: '10px 10px 0px 0px ', backgroundImage: `url(${"https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + location.state.courseId + '.png'})`, backgroundSize: "contain", backgroundRepeat: 'no-repeat' }}>
                <Grid sx={{ margin: '0% 5%', padding: "10% 0 0 0" }}>
                    <Typography variant="h1" sx={{ color: '#fff', fontFamily: 'poppins', fontWeight: "400" }}>
                        {location.state.details.title}
                    </Typography>

                    <br />
                    <br />
                    <NavLink to={'/ing/Coursess'} state={location.state} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" sx={{ backgroundColor: '#1a50b2', borderRadius: '10px', color: 'primary' }}>
                            Start Learning
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
            <Grid container sx={{ border: '0px', borderRadius: '0px', minWidth: '100%' }}>
                {/* */}
                <Grid container sx={{ border: '0px', borderRadius: '0px', minWidth: '100%', paddingTop: '3%' }}>
                    <Grid item lg={7.5} xs={12}>
                        <Grid container item xs={12} sx={{

                            borderTopLeftRadius: "12px",
                            borderTopRightRadius: "12px",
                            boxShadow: "",
                        }} >
                            <Grid lg={2}>
                                <Typography>
                                    <Button
                                        onClick={() => {
                                            return setValue(0);
                                        }}
                                        sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        About
                                    </Button>
                                </Typography>
                            </Grid>
                            <Grid lg={2}>
                                <Typography>
                                    <Button
                                        onClick={() => {
                                            return setValue(1);
                                        }}
                                        sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Content
                                    </Button>
                                </Typography>
                            </Grid>
                            <Grid lg={2}>
                                <Typography>
                                    <Button
                                        onClick={() => {
                                            return setValue(3);
                                        }}
                                        sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Reviews
                                    </Button>
                                </Typography>
                            </Grid>
                            <Grid lg={2}>
                                <Typography>
                                    <Button
                                        onClick={() => {
                                            return setValue(5);
                                        }}
                                        sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Instructor
                                    </Button>
                                </Typography>
                            </Grid>
                            <Grid lg={2}>
                                <Typography>
                                    <Button
                                        onClick={() => {
                                            return setValue(4);
                                        }}
                                        sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Discussions
                                    </Button>
                                </Typography>
                            </Grid>
                            <Grid lg={2}>
                                <Typography>
                                    <Button
                                        onClick={() => {
                                            return setValue(2);
                                        }}
                                        sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        FAQS
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ padding: '1% 0%', width: '90%' }} />
                        <Grid container item xs={12} sx={{
                            borderTop: "none",
                            borderRadius: "12px",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            // marginX: "12px"
                        }}>
                            {value == 0 ? (
                                <About details={location.state.details} />
                            ) : value == 1 ? (
                                <LatestUpdates courseContent={location.state.courseContent} />
                            ) : value == 3 ? (
                                <Reviews />
                            ) : value == 2 ? (
                                <Faqs />
                            ) : value == 5 ? (
                                <Instructor />
                            ) : value == 4 ? (
                                <Discussions />
                            ) : (
                                ''
                            )}

                        </Grid>
                    </Grid>
                    <Grid container item lg={4.5} xs={12}>
                        <Currentpro />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CourseLayout;
