import { Grid, Typography } from '@mui/material';
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import DescriptionCourse from './Description';
import CircleIcon from '@mui/icons-material/Circle';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';

const Typography1 = styled(Typography)({
    fontFamily:'Roboto',
    fontSize: '14px',
    textAlign: 'justify'
})
const Typography2 = styled(Typography)({
    fontFamily:'Roboto',
    fontSize: '24px', 
    fontWeight: '500'
})

const About = ({ details }) => {
    console.log(details)
    return (
        <Grid container lg={12} xs={12} sx={{ marginTop: '3%', padding: '1% 2% 2% 2%' }}>
            <Grid lg={12} xs={12}>
                <Typography2 variant="h2" sx={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Mulish', letterSpacing: '0.005em' }}>What you'll learn</Typography2>
                <Grid container>
                    {
                        details.skills.map((item, itemIndex) => {
                            return (
                                <Grid key={item} item lg={12} xs={12} sx={{ margin: '1% 3% -1% 3%', display: 'flex' }}>
                                    <CircleIcon sx={{ fontSize: 'small', marginTop: "1.5%", marginRight: '2%' }} />
                                    <Typography1 sx={{ letterSpacing: '0.8', marginTop: "1%" , fontFamily:"Roboto"}}> {item}</Typography1>
                                </Grid>

                            )
                        })
                    }

                </Grid>
            </Grid>
            <Divider sx={{ padding: '1% 0%', width: '100%' }} />
            <Grid sx={{ my: 2 }} item xs={12}>
                <Typography2 variant="h2" sx={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Roboto', letterSpacing: '0.005em', marginBottom: "4px" }}>Requirement</Typography2>
                <Typography1 sx={{ letterSpacing: '0.8', textAlign: 'justify', fontFamily: "Roboto" }}>{details.requirement}</Typography1>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid >
                <Typography2 variant="h2" sx={{ fontSize: '24px', fontWeight: '500', fontFamily: 'Roboto', letterSpacing: '0.005em', marginBottom: '-1%', marginTop: '1%' }}>Description</Typography2>
                {/* <Typography sx={{ margin: '2% 0%', fontSize: '14px', fontFamily: 'Mulish', letterSpacing: '0.8' }}>
                   {details.description}
                </Typography> */}
                <DescriptionCourse data={details.description} />
            </Grid>
            <Typography></Typography>
        </Grid>
    );
};

export default About;
