import React, { createContext, useEffect } from "react";
import { useState, useContext } from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  Button,
  Avatar
  , Divider,
  TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import kin from "../images/kin.png";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import tag from '../images/tag.png';
import tag1 from '../images/tag1.png';
import tag2 from '../images/tag2.png';
import JoditC from "./JoditC";
import { validate } from "uuid";
import { UserContext } from "ContextFiles";
import axios from "axios";
import { setInteractive } from "@amcharts/amcharts5/.internal/core/util/Utils";
import imgss from "../../formlogin/components/Preview.jpg"


export const Stucontext = createContext()


const Input = styled('input')({
  display: 'none',
});
const BasicInfo = () => {
  const [aadharImg, setAadharImg] = useState(null)
  const [aadharFile, setAadharFile] = useState("")
  const[studentImage, setstudentImage] = useState("")
  const[studentFile, setstudentFile] = useState("")
  const [data, setdata] = useState("")
  const handleUpload = event => {
    let file = event.target.files[0];
    setAadharFile(file)

    let reader = new FileReader();

    console.log(file);
    reader.onload = function (e) {
      setAadharImg(e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };
  const [user, setUser] = useState({
    name: "",
    email: "",
    location: "",
    Bio: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    courses: "",
    research: "",
    incubation: "",
    jobportal: ""

  })
  const [file, setFile] = useState(null);

  const fileChangedHandler = event => {
    let file = event.target.files[0];
    setinf({ ...inf, imageFile: event.target.files[0] })
    console.log(inf)
    let reader = new FileReader();

    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      return false;
    } else if (file.size > 1e6) {
      window.alert("Please upload a file smaller than 1 MB");
      return false;
    } else {
      console.log(file);
      reader.onload = function (e) {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const fileRemover = event => {
    setFile(null)
  }

  function Validate(){
    var password = document.getElementById("txtPassword").value;
    var confirmPassword = document.getElementById("txtConfirmPassword").value;
    if (password != confirmPassword) {
      alert("Passwords do not match.");
      return false;
    }
    return true;
  }
  const { authState } = useContext(UserContext);
  console.log(authState)
  console.log(data)
  const [inf, setinf] = React.useState(
    {
      Stuid: authState.user.id,
      Stuemail: authState.user.email,
      StuName: authState.user.name,
      Stulocation: '',
      StuBio: '',
      ImageUrl: ""
    })

  useEffect(async () => {
    const data1 = { "Stuemail": authState.user.email }
    console.log(data1)
    // await axios({
    //   url: 'https://lmsapi.keewesolutions.com/Stupro/Getinfo',
    //   method: 'POST',
    //   data: data1
    // }).then((res) => {
    //   console.log(res.data.Items[0])
    //   setinf(res.data.Items[0])
    // })

    async function studentData(){
      await axios({
        url:'https://cms.keewesolutions.com/user/userData',
        method:'POST',
        data:{email:authState.user.email}
      }).then((res)=>{
        console.log(res.data)
        if(res.data.Items[0]){
        setdata(res.data.Items[0])}
      })
    }
    studentData()
  }, [])


  const submit = async () => {
    alert('Your Information is saved.')
    // console.log(inf)
    // const formData = new FormData();
    // formData.append('Stuid', inf.Stuid)
    // formData.append('StuName', inf.StuName)
    // formData.append('Stuemail', inf.Stuemail)
    // formData.append('StuBio', inf.StuBio)
    // formData.append('Stulocation', inf.Stulocation)
    // formData.append('imageFile', inf.imageFile)

    // await axios({
    //   url: 'https://lmsapi.keewesolutions.com/Stupro/Stuprofile',
    //   method: 'POST',
    //   data: formData
    // }).then((res) => {
    //   console.log(res)
    // })

    console.log(data)
    const formData1 = new FormData();
    formData1.append('name',data.name)
    formData1.append('supportCenter',data.supportCenter)
    formData1.append('phone',data.phone)
    formData1.append('username',data.username)
    formData1.append('userIdAP',data.userIdAP)
    formData1.append('userIdSC',data.userIdSC)
    formData1.append('email',authState.user.email)
    formData1.append('studentFile',data.studentFile)
    formData1.append('fathername',data.fathername)
    formData1.append('mothername',data.mothername)
    formData1.append('qualification',data.qualification)
    formData1.append('aadhar',data.aadhar)
    formData1.append('aadharFile',data.aadharFile)
    formData1.append('ImageUrl',data.ImageUrl)
    formData1.append('aadharImg',data.aadharImg)

    await axios({
      url: 'https://cms.keewesolutions.com/user/putUserData',
      method:'POST',
      data: formData1
    }).then((res)=>{
      console.log(res)
    })

  }

  return (
    <Stucontext.Provider value={{ inf, setinf }}>
      <Grid container sx={{ marginBottom: '30px' }}>
        <Grid xs={2}></Grid>

        <Grid xs={12} lg={8} sx={{ backgroundColor: "white" }}>
          <Box>
            <Box display={"flex"}>
              <img src={tag} alt="" />
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "800",
                  fontSize: "26px",
                  lineHeight: "120%",
                  color: "#25324B",
                  marginLeft: '2%'
                }}
              >
                Profile Information
              </Typography>
            </Box>

            <Grid xs={12} display={"flex"} marginTop={"3%"}>
              <Grid xs={2}>
                {data.ImageUrl != "" ?
                  <Avatar
                    src={data.ImageUrl}
                    alt="kin"
                    sx={{ width: "96px", height: "96px" }}
                  /> :
                  <Avatar
                    src={file}
                    alt="kin"
                    sx={{ width: "96px", height: "96px" }}
                  />
                }
              </Grid>


              <Grid xs={10}
                sx={{
                  display: "flex",
                  marginLeft: "16%",
                  marginTop: '3%'
                }}
              >
                {/* <label htmlFor="contained-button-file">
                  <Input inputProps={{ accept: 'image/*' }} id="contained-button-file" multiple type="file" onChange={fileChangedHandler} />
                  <Button
                    component="span"
                    variant="contained"
                    sx={{
                      backgroundColor: "#2A85FF",
                      borderRadius: "12px",
                      height: "48px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "white",
                      textTransform: "capitalize",

                    }}
                  >
                    <AddIcon />
                    Upload new picture
                  </Button>
                </label>

                <Button
                  variant="outlined" onClick={fileRemover}
                  sx={{
                    backgroundColor: "#FCFCFC",
                    border: "1px solid #EFEFEF",
                    borderRadius: "12px",
                    marginLeft: "4%",

                    height: "48px",
                    fontFamily: "poppins",
                    fontWeight: "700",
                    fontSize: "13px",
                    lineHeight: "24px",
                    letterSpacing: "-0.01em",
                    color: "#000000",
                    textTransform: "capitalize",
                    minWidth: '90px'
                  }}
                >
                  Remove
                </Button> */}
              </Grid>

            </Grid>


            <form>
              <Grid sx={{ marginTop: "0%" }}>
                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "303%",
                      color: "#515B6F",
                      
                    }}
                  >
                    Display name
                  </Typography>
                  <OutlinedInput sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} value={data.name} label={data.name}
                   fullWidth/>
                {/* </FormControl> */}
                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth > */}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    Email
                  </Typography>
                  <OutlinedInput sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} value={data.email}  label={data.email} fullWidth/>
                {/* </FormControl> */}

                
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    Father's Name
                  </Typography>
                  <TextField sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} fullWidth value={data.fathername} onChange={e => setdata({ ...data, fathername: e.target.value })} />
               

                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    Mother's Name
                  </Typography>
                  <TextField sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} fullWidth value={data.mothername} onChange={e => setdata({ ...data, mothername: e.target.value })} />
                {/* </FormControl> */}

                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    Qualification
                  </Typography>
                  <TextField sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} fullWidth value={data.qualification} onChange={e => setdata({ ...data, qualification: e.target.value })} />
                {/* </FormControl> */}

                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    Aadhar Card Number
                  </Typography>
                  <TextField sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} fullWidth value={data.aadhar} onChange={e => setdata({ ...data, aadhar: e.target.value })} />
                {/* </FormControl> */}

                <Grid item lg={12} xs={4} sm={8} md={8} sx={{ marginBottom: "10px", marginTop: "10px" }}  >
                  <div style={{ marginTop: '12px', marginBottom: '24px', display: 'flex' }}>
                    <div>

                      {
                        data.aadharImg == null ?
                          (
                            <img src={imgss}
                              alt="asdf"
                              style={{
                                width: "200px",
                                height: "100px",
                                objectFit: "contain"
                              }} />
                          )
                          :
                          (
                            <img src={data.aadharImg}
                              alt="asdf"
                              style={{
                                width: "200px",
                                height: "100px",
                                objectFit: "contain"
                              }} />
                          )
                      }

                      <div>
                        <label>
                          <input accept="image/*" type="file" style={{
                            display: 'none'
                          }}
                            onChange={e => {
                              // setdata({...data,aadharImg:})
                              // setImageUrl(URL.createObjectURL(e.target.files[0]))
                              // setCourseImageFile(e.target.files[0])
                             setdata({...data,aadharFile:e.target.files[0],aadharImg:URL.createObjectURL(e.target.files[0])})
                            }}

                          />
                          <Button

                            onClick={() => {
                              console.log(data.aadharImg)

                            }}
                            variant="contained"
                            sx={{ backgroundColor: "#06213F",width:"180px", borderRadius: "0px", marginBottom: "10px" ,marginLeft:"10px"}}


                            // variant="contained"
                            component="span"

                          >
                            Upload Aadhar Card

                          </Button>
                        </label>
                      </div>
                    </div>
                    <div>

                      {
                        data.ImageUrl == null ?
                          (
                            <img src={imgss}
                              alt="asdf"
                              style={{
                                width: "200px",
                                height: "100px",
                                objectFit: "contain"
                              }} />
                          )
                          :
                          (
                            <img src={data.ImageUrl}
                              alt="asdf"
                              style={{
                                width: "200px",
                                height: "100px",
                                objectFit: "contain"
                              }} />
                          )
                      }

                      <div>
                        <label>
                          <input accept="image/*" type="file" style={{
                            display: 'none'
                          }}
                            onChange={e => {
                              // setdata({...data,aadharImg:})
                              // setImageUrl(URL.createObjectURL(e.target.files[0]))
                              // setCourseImageFile(e.target.files[0])
                              setdata({...data,studentFile:e.target.files[0],ImageUrl:URL.createObjectURL(e.target.files[0])})
                            }}

                          />
                          <Button

                            onClick={() => {
                              console.log(data.ImageUrl)

                            }}
                            variant="contained"
                            sx={{ backgroundColor: "#06213F",width:"180px", borderRadius: "0px", marginBottom: "10px" ,marginLeft:"10px"}}


                            // variant="contained"
                            component="span"

                          >
                            Upload Your Image

                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                </Grid>



                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  {/* <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    Location
                  </Typography>
                  <TextField sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} fullWidth value={inf.Stulocation} onChange={e => setinf({ ...inf, Stulocation: e.target.value })} /> */}
                {/* </FormControl> */}
                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  {/* <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "20px",
                      marginTop: "3%",
                      color: "#515B6F",
                    }}
                  >
                    Bio
                  </Typography>
                  <TextField rows={4} multiline value={inf.StuBio} fullWidth onChange={e => setinf({ ...inf, StuBio: e.target.value })} /> */}
                {/* </FormControl> */}
                {/* <Divider sx={{ marginTop: '2%' }} />
                <Box display={"flex"}>
                  <img src={tag1} alt="" style={{ marginTop: '2%' }} />
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "1000",
                      fontSize: "24px",
                      marginTop: "4%",
                      color: "#25324B",
                      marginLeft: '2%'

                    }}
                  >
                    Login
                  </Typography>
                </Box> */}

                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  {/* <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "280%",
                      color: "#515B6F",
                    }}
                  >
                    Old Password
                  </Typography>
                  <OutlinedInput sx={{ borderRadius: '19px', backgroundColor: '#F4F4F4' }} /> */}
                {/* </FormControl> */}
                {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth> */}
                  {/* <Grid display={"flex"} xs={12}>
                    <Grid xs={6}>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "160%",
                          color: "#515B6F",
                        }}
                      >
                        New password
                      </Typography>
                      <OutlinedInput sx={{ width: "99%", borderRadius: '19px', backgroundColor: '#F4F4F4' }} id="txtPassword" />
                    </Grid>
                    <Grid xs={6}>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "160%",
                          color: "#515B6F",
                        }}
                      >
                        Confirm
                      </Typography>
                      <OutlinedInput sx={{ width: "99%", borderRadius: '19px', backgroundColor: '#F4F4F4' }} id="txtConfirmPassword" />
                    </Grid>
                  </Grid> */}
                {/* </FormControl> */}
{/* 
                <Button onClick={Validate}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#FCFCFC",
                    border: "2px solid #EFEFEF",
                    boxSizing: "border-box",
                    borderRadius: "12px",
                    marginLeft: "1%",
                    marginTop: "2%",
                    height: "48px",
                    fontFamily: "poppins",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "-0.01em",
                    color: "#1A1D1F",
                    textTransform: "capitalize",
                  }}
                >
                  Update password
                </Button>
                <Divider sx={{ marginTop: '4%' }} /> */}
                {/* <Box display={"flex"}>
                  <img src={tag2} alt="" style={{ marginTop: '4%' }} />
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "1000",
                      fontSize: "24px",
                      marginTop: "4%",
                      color: "#25324B",
                      marginLeft: '2%'
                    }}
                  >
                    Notifications
                  </Typography>
                </Box>

                <Grid display={"flex"} xs={12} marginTop={"3%"} >
                  <Grid xs={6}>
                    <Typography sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",

                    }}>
                      Courses
                    </Typography>

                  </Grid>
                  <Grid xs={6} justifyContent={"end"} display={"flex"}>
                    <Switch checked={user.courses}
                      onChange={e => setUser({ ...user, courses: e.target.value })} />
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: '1%' }} />

                <Grid display={"flex"} xs={12} marginTop={"3%"} >
                  <Grid xs={6}>
                    <Typography sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",

                    }}>
                      Job Portal
                    </Typography>

                  </Grid>
                  <Grid xs={6} justifyContent={"end"} display={"flex"}>
                    <Switch />
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: '1%' }} />


                <Grid display={"flex"} xs={12} marginTop={"3%"} >
                  <Grid xs={6}>
                    <Typography sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",

                    }}>
                      Incubation
                    </Typography>

                  </Grid>
                  <Grid xs={6} justifyContent={"end"} display={"flex"}>
                    <Switch />
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: '1%' }} />
                <Grid display={"flex"} xs={12} marginTop={"3%"} >
                  <Grid xs={6}>
                    <Typography sx={{
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "160%",
                      color: "#515B6F",

                    }}>
                      Research
                    </Typography>

                  </Grid>
                  <Grid xs={6} justifyContent={"end"} display={"flex"}>
                    <Switch checked={true}
                    />
                  </Grid>
                </Grid> 
                <Divider sx={{ marginTop: '4%' }} />*/}


                <Button
                  variant="contained"
                  sx={{
                    ':hover': {
                      backgroundColor: '#fcfcfc',
                      color: '#1e88e5'
                    },
                    borderRadius: "12px",
                    height: "44px",
                    fontFamily: "poppins",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "-0.01em",
                    color: "#FCFCFC",
                    textTransform: "capitalize",
                    minWidth: '90px',
                    marginTop: '6%'

                  }} onClick={submit}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ':hover': {
                      backgroundColor: '#1e88e5',
                      color: '#fcfcfc'
                    },

                    backgroundColor: '#fcfcfc',
                    borderRadius: "12px",
                    height: "44px",
                    fontFamily: "poppins",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "-0.01em",
                    color: "#1e88e5",
                    textTransform: "capitalize",
                    minWidth: '90px',
                    marginTop: '6%',
                    marginLeft: '2%'

                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </form>
          </Box>
        </Grid>

        <Grid xs={2}></Grid>
      </Grid>
    </Stucontext.Provider>
  );
};

export default BasicInfo;
