import * as React from 'react';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RegisterCard from './RegisterCard';
import Register from './Register';
import BasicCard from './skewCard';
import Login from './Login';
import { useNavigate } from 'react-router-dom';
import WebcamCapture  from './RegisterStep2'
import { Button, Divider } from '@mui/material';
import Nav from './Nav';
import Footer from '../../ILETS/footer'
import TopNav from 'ILETS/TopNav';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabComponent(props) {
  const navigate=useNavigate();
  const {id,search}=props
  console.log(props,"dsdfrfrg")

  const handleLogin=()=>{
    navigate({
      pathname: "/login/",
      search: search,
    })  
  }
  const handleRegister=()=>{
    navigate({
      pathname: "/register/",
      search:search,
    })  
  }

  return (
    <Box sx={{ width: '100%' }}>
   <TopNav/>
      {/* <Box sx={{   }}>
        <Tabs  aria-label="tabs example">
         {id==0? <Tab style={{fontSize:"1.2rem", fontWeight:"700"}} label="Register " onClick={handleRegister} {...a11yProps(id)} />:""}
          {id==1?<Tab style={{fontSize:"1.2rem", fontWeight:"700"}} label="Sign In " onClick={handleLogin} {...a11yProps(id)} />:""}
          {id==2?<Tab style={{fontSize:"1.2rem", fontWeight:"700"}} label="Register Step 2 "   />:""}
        </Tabs>
      </Box> */}
      <TabPanel value={id} index={0} >
         <Register/>
      </TabPanel>
      <TabPanel value={id} index={1}>
         <Login/>
      </TabPanel>

      <TabPanel value={id} index={2}>
      <WebcamCapture />
      </TabPanel>
     
     
     <Footer/>
     
      
    </Box>
  );
}