import { React, useContext, useState } from 'react';
import Card from '@mui/material/Card';
import { Container, Typography, Grid, Divider } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { AssesmentContext } from 'ContextFiles';
import Light from './light';
import axios from 'axios';

export default function Mcq({ userId,courseId }) {
	const [value, setValue] = useState('');
	const [isChecked, setChecked] = useState(false)
	const assessmentObject = useContext(AssesmentContext);
	const handleChange = (event) => {
		setValue(event.target.value);

	};
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const responseChange = (e) => {
		let newAssesment = { ...assessmentObject }
		let currentQuestionobject = newAssesment.questions[currentQuestionIndex]
		console.log(currentQuestionobject["CORRECT OPTION"])
		let id = currentQuestionobject.id
		newAssesment.responses[id] = value
		console.log(value);

		if (value == "" && isChecked) {
			newAssesment.status[id] = 1
		} else if (
			value == "" && !isChecked
		) {
			newAssesment.status[id] = 0
		} else { newAssesment.status[id] = -1 }

		if(currentQuestionobject["CORRECT OPTION"]==value){
			newAssesment.score=newAssesment.score+1;
		}
		console.log(newAssesment.score)
		assessmentObject.setNewContext(newAssesment)
		setValue("")
		setCurrentQuestionIndex(currentQuestionIndex + 1)
	}

	const FinalSubmit=async ()=>{
		console.log("hey submmited")
		axios({
			url:"https://cms.keewesolutions.com/FinalAssesment",
			method:"POST",
			data:{
				userId,
				courseId,
				...assessmentObject,
				TimeStamp:Date.now(),
				Approve:false,
				Url:""
			}
		}).then(res=>{
			alert("Assignment is submitted successfully");
		})

	}
	return (
		<>
			{
				assessmentObject.questions.length != 0 ?
					(
						<Card
							sx={{ padding: '1%', borderRadius: '18px', margin: '8px', display: 'flex', minWidth: '99%', justifyContent: 'start' }}
						>
							<Grid xs={4} style={{ borderRight: '1.5px solid #b3b3b3', paddingRight: '0.8%', height: '50%' }}>
								<Light setCurrentIndex={setCurrentQuestionIndex} />
							</Grid>
							<Grid xs={8} sx={{ margin: '2%', boxShadow: '5px 7px 12px #888888', padding: '2%', width: '80%', borderRadius: '15px' }}>
								<FormControl sx={{ m: 0 }} variant="standard">
									<FormLabel
										sx={{
											fontSize: '24px',
											color: 'black',
											fontFamily: 'sans-serif'
										}}
									>
										{assessmentObject.questions[currentQuestionIndex].QUESTION}
									</FormLabel>
									<RadioGroup size="small" value={value} name="quiz" onChange={handleChange}>
										{/* Option A */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={assessmentObject.questions[currentQuestionIndex]["OPTION A"]}
											control={<Radio />}
											label={assessmentObject.questions[currentQuestionIndex]["OPTION A"]}
										/>

										{/* Option B */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={assessmentObject.questions[currentQuestionIndex]["OPTION B"]}
											control={<Radio />}
											label={assessmentObject.questions[currentQuestionIndex]["OPTION B"]}
										/>

										{/* Option C */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={assessmentObject.questions[currentQuestionIndex]["OPTION C"]}
											control={<Radio />}
											label={assessmentObject.questions[currentQuestionIndex]["OPTION C"]}
										/>

										{/* Option D */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={assessmentObject.questions[currentQuestionIndex]["OPTION D"]}
											control={<Radio />}
											label={assessmentObject.questions[currentQuestionIndex]["OPTION D"]}
										/>
									</RadioGroup>
								</FormControl>

								<div>
									{currentQuestionIndex!=0?
									<Button size="small" >Previous</Button>:""}
									{currentQuestionIndex==((assessmentObject.questions).length-1)?
									<><Button size="small" onClick={FinalSubmit}>Submit</Button></>
									:<Button size="small" onClick={responseChange}>Next</Button>}
									
								</div>

								<div>
									<FormControlLabel control={<Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)} />} label="Mark as preview" />
								</div>
								{/* <Button sx={{float:"right"}} onClick={FinalSubmit}>Submit Assesment</Button> */}
							</Grid>
						</Card>
					) :
					null
			}
		</>
	);
}
