import React, { Component, useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  ModalBody,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Grid, Select, TextField } from "@mui/material";
import logonew from "../images/logonew.png";
import logonew1 from "../images/logonew1.png";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Apps from "@mui/icons-material/Apps";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import plan from "../images/airplane.jpg";
import { Box, Typography } from "@mui/material";
import '../container/MainPage.css';
import Awards from "./Awards";
import PgProgramsCards from "./PgProgramsCards";
import countries from "../images/countries logo copy.png";
import Experience from "./Experience";
import Footer from "./footer";
import dbu from "../images/dbu.png";
import { Flag, InvertColors } from "@mui/icons-material";
import Register from "./Register";
import Nav1 from "./Navbar";
import Caro from "./Caro";
import logonew2 from "./Images/logonew2.png";
import logonew3 from "./Images/logonew3.png";
import logonew4 from "./Images/logonew4.jpg";
import Testimonial from "./Testimonial";
import Categories from "./Categories";
import logone from "./Images/logone.png";
import Flagu from "./Flagu";
import MyOpen from "./tieup";
import TopNav from "./TopNav";
import logone1 from './Images/logone1.png';
import logone2 from './Images/logone2.png';
import FlagMobile from "./FlagMobile";
import FlagTab from "./FlagTab";


import lapi7 from './Images/lapi7.jpg';
import Companies from "./Companies";
import CompaniesMobile from "./CompaniesMobile";
import CompaniesTab from "./CompaniesTab";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Page() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Grid container>
      <TopNav/>
      {/* <Nav1 /> */}
      <Grid
        item xs={12} lg={12} md={12} sm={12}
        className="im"
       
      >
        <Grid
          container
          className="to"
          sx={{}}
        >
          <Grid lg={1}></Grid>
          <Grid lg={5} xs={12} md={12} sm={12} xl={5} marginTop={'8%'} padding={'2%'}> 
            <div className="cen" style={{}}>
              <img
              className="no1"
                src={logone2}
                alt=""
                style={{ height: "100px", marginBottom: "2%" }}
              />
              <Typography
                className="tex"
                sx={{ 
                  fontSize: "46px",
                  color: "#fff",
                  fontWeight: "450",
                  fontFamily: "Roboto",
                  lineHeight: "1.3",
                }}
              >
                Your Passport to your<br /> Ultimate Destination
              </Typography>
            </div>
            <div
              className="cen"
              style={{ marginTop: "4.5%" }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  color: "#fff",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  marginTop: "-3%",
                }}
              >
                Desh Bhagat Global Services
              </Typography>
              <Typography
                className="tex"
                sx={{
                  fontSize: "20px",
                  color: "#fff",
                  fontWeight: "300",
                  fontFamily: "Roboto",
                  marginTop: "4%",
                }}
              >
                IELTS Plus Foundation Programs
              </Typography>

              
            </div>
          </Grid>
          <Grid item lg={6} xs={12} md={12} sm={12} xl={6} className="yo1" sx={{display:'flex',justifyContent:'end'}}>
            <img src={lapi7} alt="" style={{width:'100%'}}/>
          </Grid>
          
        </Grid>
      </Grid>

      
      <FlagTab/>
      <FlagMobile/>
      <Flagu />
      <PgProgramsCards />
      <Categories />
      
      <Experience />
      <Companies/>
      <CompaniesTab/>
      <CompaniesMobile/>
      <MyOpen />
     
      <Testimonial />

      <Footer />
    </Grid>
  );
}
