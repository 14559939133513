import React, { useState } from 'react';
// import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, Box, IconButton, Paper } from '@mui/material';
import { menuu } from './menuu';
import { hasChildren } from './utilss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Hour({ courseContent, setUrl, currentDetails, setAssesment, progressData }) {
	console.log(courseContent);
	const nest = courseContent.data.map((val, index) => {
		// return <NestedList heading={val.name} data={val.topics} subdata={val.topics.subTopics} />;
		return <ModTile topicArray={val.topics} mod={val} key={val.id} setUrl={setUrl} currentDetails={currentDetails} modIndex={index} setAssesment={setAssesment} progressData={progressData} />;
	});

	return (
		<Paper sx={{ border: '0 1px 1px 1px solid gray', padding: "0px 4px", backgroundColor: "#fff", borderRadius: 0, marginLeft: "10px", maxHeight: '500px', overflowY: "scroll", overFlowX: "hidden" }}>
			<List sx={{ width: '100%' }} component="nav" aria-labelledby="nested-list-subheader" children={nest} />
		</Paper>
	);
}

const ModTile = ({ topicArray, mod, setUrl, currentDetails, modIndex, setAssesment, progressData }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<Box
				key={mod.id}
				onClick={() => {
					setOpen(!open);
				}}
				sx={{
					width: '100%',
					height: '50px',
					backgroundColor: '#fff',
					paddingTop: '2%'
				}}
			>
				<span style={{ fontSize: '16px', fontFamily: 'Mulish', fontWeight: '700', color: '#000' }}>

					{
						mod.name.length > 38 ? (
							mod.name.substring(0, 37) + "..."
						) : (
							mod.name
						)
					}

					<IconButton

						sx={{
							transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
							float: 'right',
							marginTop: '-2%',
							// marginLeft: '5%'
						}}
					>
						<KeyboardArrowDownIcon />
					</IconButton>
				</span>

			</Box>
			<Divider />
			<Collapse in={open}>
				{topicArray.map((topic, topicIndex) => {
					return (
						<TopicTile
							setUrl={setUrl}
							topic={topic}
							key={topic.id + topicIndex}
							subTopicArray={topic.subTopics}
							currentDetails={currentDetails}
							topicIndex={topicIndex}
							modIndex={modIndex}
							setAssesment={setAssesment}
							progressData={progressData}
						/>
					);
				})}
			</Collapse>
		</>
	);
};

const TopicTile = ({ subTopicArray, topic, setUrl, currentDetails, topicIndex, modIndex, setAssesment, progressData }) => {
	const [open1, setOpen1] = useState(false);
	return (
		<>
			<Box
				key={topic.id}
				onClick={() => {
					setOpen1(!open1);
				}}
				sx={{
					width: '100%',
					height: '50px',
					backgroundColor: '#fff',

					padding: '3% 0 0 2%'
				}}
			>
				<span style={{ fontSize: '16px', fontFamily: 'Mulish', fontWeight: '700', color: '#000' }}>
					{
						topic.name.length > 40 ? (
							topic.name.substring(0, 39) + "..."
						) : (
							topic.name
						)
					}

				</span>

				<IconButton

					sx={{
						transform: !open1 ? 'rotate(0deg)' : 'rotate(180deg)',
						float: 'right',
						// marginTop: '-1%'
					}}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</Box>
			<Divider />
			<Collapse in={open1} sx={{}}>
				{subTopicArray.map((subTopic, subTopicIndex) => {
					return (
						<SubTopicTile
							setUrl={setUrl}
							subTopic={subTopic}
							key={subTopic.id}
							currentDetails={currentDetails}
							modIndex={modIndex}
							topicIndex={topicIndex}
							subTopicIndex={subTopicIndex}
							setAssesment={setAssesment}
							progressData={progressData}
						/>
					);
				})}
			</Collapse>
		</>
	);
};

const SubTopicTile = ({ subTopic, setUrl, currentDetails, modIndex, topicIndex, subTopicIndex, setAssesment, progressData }) => {
	return (
		<>
			<Box
				key={subTopic.id}
				onClick={() => {
					// setOpen1(!open1);
					setUrl(subTopic.id);
					let newCurrentDetails = {
						mI: modIndex,
						tI: topicIndex,
						sI: subTopicIndex
					};
					currentDetails(newCurrentDetails);
					setAssesment(false)
				}}
				sx={{
					width: '100%',
					height: '50px',
					backgroundColor: '#fff',

					padding: '3% 0 0 2%'
				}}
			>
				<IconButton
					sx={{
						// transform: !open1 ? 'rotate(0deg)' : 'rotate(180deg)',
						float: 'left',
						marginTop: '-3%'
					}}
				>

					{/* <PlayCircleOutlineIcon />
					 */}
					{progressData != null ? (progressData.progressRecord[subTopic.id] === 1 ? <CheckCircleOutlineIcon sx={{ color: "green" }} /> : <PlayCircleOutlineIcon />) : <PlayCircleOutlineIcon />}
				</IconButton>
				<span style={{ fontSize: '14px', fontFamily: 'Mulish', fontWeight: '600', color: progressData != null ? (progressData.progressRecord[subTopic.id] === 1 ? 'green' : 'black') : 'black', }}>
					{
						subTopic.name.length > 40 ? (
							subTopic.name.substring(0, 39) + "..."
						) : (
							subTopic.name
						)
					}

				</span>
			</Box>
			<Divider />
		</>

	);
};
