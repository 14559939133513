import React from 'react';
import { Typography,Grid,Box } from '@mui/material';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
import logo from '../images/logo.png';
import "./Testimonial.css";
import comp1 from './Images/comp1.png';
import comp2 from './Images/comp2.png';
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Companies = () => {
  return (
    <Grid item className='desk' container xs={12} lg={12} md={12} sm={12} sx={{ justifyContent: 'center' ,bgcolor:'#fff'}}>
    <Box sx={{ width: '80%',marginBottom:'4%' }}>
    <Typography sx={{fontSize:'28px',fontWeight:'500',color:'#272c37',fontFamily:'Roboto',marginBottom:'2%',marginTop:'2%',textAlign:'center'}}>MOU's signed with Companies</Typography>
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }} pagination={{ "clickable": true }} navigation={false}
            className="swip" style={{ height: '240px', backgroundColor: '#edf2f6', border: '1px solid #eee', borderRadius: '4px' }}>
            <ul className="swiper-wrapper slides">
                <SwiperSlide >
                    <Grid >

                        <div style={{}}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={comp1}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={comp2}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
               
             
            </ul>
        </Swiper>

    </Box>
</Grid>
  )
}

export default Companies
