import React from 'react';
import { Typography,Grid,Box } from '@mui/material';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
import logo from '../images/logo.png';
import "./Testimonial.css";

import ate from './Images/ate.png';
import ceven from './Images/ceven.png';
import cix from './Images/cix.png';
import fiv from './Images/fiv.png';
import fore from './Images/fore.png';
import thre from './Images/thre.png';
import to from './Images/to.png';
import wn from './Images/wn.png';

import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const CompaniesMobile = () => {
  return (
    <Grid item className='desk1' container xs={12} lg={12} md={12} sm={12} sx={{ justifyContent: 'center' ,bgcolor:'#fff'}}>
    <Box sx={{ width: '80%',marginBottom:'4%' }}>
    <Typography sx={{fontSize:'28px',fontWeight:'500',color:'#272c37',fontFamily:'Roboto',marginBottom:'2%',marginTop:'2%',textAlign:'center'}}>MOU's signed with Companies</Typography>
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }} pagination={{ "clickable": true }} navigation={false}
            className="swip1" style={{ height: '185px', backgroundColor: '#edf2f6', border: '1px solid #eee', borderRadius: '4px',padding:'2%' }}>
            <ul className="swiper-wrapper slides">
                <SwiperSlide >
                    <Grid >

                        <div style={{}}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={wn}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={to}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={thre}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={fore}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={fiv}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={cix}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={ceven}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={ate}
                                    style={{ height: "auto", width: '100%', }} alt="not found"
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
               
            </ul>
        </Swiper>

    </Box>
</Grid>
  )
}

export default CompaniesMobile
