import React, { useState, useEffect, useContext } from 'react';
import { Alert, AlertTitle, Button, Divider, Grid, Typography } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import { Box } from '@mui/system';
import { renderToStaticMarkup } from "react-dom/server";
import axios from 'axios';
import parse from 'html-react-parser';
import { UserContext } from 'ContextFiles';
import Appaa from '../../../vaibhav/coursecontent/Appaa';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Breadcrumbs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Currentpro from 'Rohan/Currentpro';
import Newcourse from './Newcourse';
import Chip from '@mui/material/Chip';
// import { useNavigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { data } from 'views/dashboard/Scheduler/Grouping';
import Hour from 'vaibhav/coursecontent/Hour';
import { useAlert } from 'react-alert';
// import vid from './456.mp4'

export default function Coursess() {
    // const [correctAnswer, setCorrectAnswer] = useState(false)
    const [selectedOption, setSelectedOption] = useState(false)
    function handleClick(event) {
        event.preventDefault();
    }
    const { authState } = useContext(UserContext);
    const [showSolution, setShowSolution] = useState({})
    const [solutionData, setSolutionData] = useState({})
    const [solutionFile, setSolutionFile] = useState("")
    const [solIndex, setSolIndex] = useState("")
    const [qid, setQid] = useState("")

    const [weeklyRecord, setWeeklyRecord] = useState({})
    const [correctAnswer, setCorrectAnswer] = useState(false)
    const userContext = useContext(UserContext)
    const [subTitle, setSubtitle] = useState()
    const [value, setValue] = useState(0);
    const [state, setstate] = useState(1);
    const [vData, setVData] = useState([])
    const [studata, setstudata] = useState("")
    const [showAssesments, setShowAssesments] = useState(false)
    const location = useLocation()
    const [assesmentArray, setAssesmentArray] = useState([])
    console.log(location)
    const [currentVideo, setCurrentVideo] = useState(
        // { module: "fe102837-7eb4-4f64-af52-ced61c675db8", topic: "acdc2dbf-853a-4dda-a779-e36543cd4e3d", subTopic: "a7d4fc36-13b5-47a0-a4b6-45c9038e2d26" }
    )
    const alert1 = useAlert();


    const [scoreObject, setScoreObject] = useState({})


    const [disable, setDisable] = useState("")

    const solutionUpload = async (x, y) => {
       

        let newScoreObject = { ...scoreObject }
        newScoreObject[y] = 1;
        setScoreObject(newScoreObject)

        if (window.confirm("Are you sure you want to upload the selected file?")) {
            const formData = new FormData();
            formData.append("userId", userContext.user.id)
            formData.append("courseId", location.state.details.id)
            formData.append("qid",qid)
            formData.append("solutionFile",solutionFile)

            await axios({
                url:"https://cms.keewesolutions.com/solution",
                method:"POST",
                data:formData
            }).then(res=>{
               
            setShowSolution({ ...showSolution, [x]: true })
            setDisable({ ...disable, [x]: true })
            })
        }
    }


    const [currentAssesment, setCurrentAssesment] = useState(null)
    const [assesmentIndex, setAssesmentIndex] = useState(0)
    const [percentage, setPercentage] = useState(0)
    console.log(location.state.courseContent)
    console.log(location.state.details.id)
    const [data, setdata] = useState()
    const [vUrl, setVUrl] = useState(() => {
        if (location.state.courseContent.data[0].topics[0]) {
            return location.state.courseContent.data[0].topics[0].subTopics[0].id
        } else {
            return null
        }
    })
    const [showAssesmentsButton, setShowAssesmentsButton] = useState(false)
    const [currentDetails, setCurrentDetails] = useState({
        mI: 0,
        tI: 0,
        sI: 0
    })
    useEffect(async () => {
        await axios({
            url: "https://backend.keewesolutions.com/dbIELTS/hourly",
            method: "post",
            data: { coursetype: "hourly" }
        }).then(res => {
            console.log(res.data)
            let arr = []
            let arr1 = []
            arr.push(location.state.details.id)
            for (let i in arr) {
                res.data.Items.filter((item) => {

                    if (arr[i] == item.id) {
                        console.log(item.id)
                        arr1.push(item.id)
                    }
                    console.log(arr1.length)
                    setdata(arr1.length)
                })
            }
           
         
         async function studentData(){
            await axios({
              url:'https://backend.keewesolutions.com/user/userData',
              method:'POST',
              data:{email:authState.user.email}
            }).then((res)=>{
              console.log(res.data.Items[0])
              setstudata(res.data.Items[0])
            })
          }
          studentData()
  


        }).catch(err => console.log(err))
    }, [])



    const navigate = useNavigate()
    const updateWeeklyRecord = async (moduleId) => {
        try {
            const rec = await axios({
                url: "https://backend.keewesolutions.com/weekly/updateWeeklyRecord",
                method: "POST",
                data: {
                    courseId: location.state.courseId,
                    userId: JSON.parse(localStorage.getItem("dbu.lmsStorage")).user.id,
                    moduleId: moduleId
                }
            })
            console.log(rec.data)
        } catch (error) {
            console.log(error)
        }
    }
    const handleVideo = () => {

        // handle video with index increment
        let dataArr = location.state.courseContent.data;
        console.log("here")
        let { mI, tI, sI } = currentDetails
        console.log("here")
        let currentModule = dataArr[mI]
        console.log(currentModule.id)
        console.log(weeklyRecord[currentModule.id])
        // if((Date.now() - weeklyRecord['time'] <= (mI*604800000))){
        //     setVUrl(null);   
        //     setShowAssesments(false)
        //     alert("Assesment Submitted, please visit next week");
        //     return 0;
        // }
        console.log("here")
        console.log(mI + "  " + tI + "  " + sI)
        let currentTopic = currentModule.topics[tI]
        let currentSubTopic = currentTopic.subTopics[sI]
        if (sI != currentTopic.subTopics.length - 1) {
            console.log("line 65")
            let newC = { ...currentDetails }
            newC.sI += 1
            setVUrl(currentTopic.subTopics[sI + 1].id)
            setCurrentDetails(newC)
        } else {
            console.log("line 71")
            if (tI != currentModule.topics.length - 1) {
                console.log("line 73")
                let newC = { ...currentDetails }
                newC.tI += 1
                newC.sI = 0
                setVUrl(currentModule.topics[tI + 1].subTopics[0].id)
                setCurrentDetails(newC)
            } else {
                console.log("line 80")
                if (mI != dataArr.length - 1) {
                    console.log("line 82")
                    let newC = { ...currentDetails }
                    newC.mI += 1
                    newC.tI = 0
                    newC.sI = 0
                    setVUrl(dataArr[mI + 1].topics[0].subTopics[0].id)
                    setCurrentDetails(newC)
                } else {
                    console.log("line 90")
                    null
                }
            }
        }
        setShowAssesments(false)
    }
    useEffect(() => { console.log(scoreObject) })
    const handleMcqs = () => {
        console.log("Here only", currentDetails)
        let assesments = location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].subTopics[currentDetails.sI].assesments
        if (assesments === undefined || assesments.length == 0) {
            console.log("Content Finished")
            handleVideo()
            return
        }
        setShowAssesments(true)
        setAssesmentArray(assesments)
        let newScoreObject = {}
        for (let assesment of assesments) {
            console.log(assesment)
            console.log("above it")
            newScoreObject[assesment.id] = 0;
        }
        console.log(newScoreObject)
        setScoreObject(newScoreObject)
        setAssesmentIndex(0)

    }
    useEffect(() => {
        if (assesmentArray != undefined) {
            console.log(assesmentArray)
            if (assesmentArray.length != 0) {
                setCurrentAssesment(
                    assesmentArray[assesmentIndex]
                )
            }
            console.log(location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].subTopics[currentDetails.sI].name);
        }
    }, [assesmentArray, assesmentIndex])
    useEffect(async () => {
        try {
            let response = await axios({
                url: process.env.REACT_APP_URL + "/db/getTranscript",
                data: {
                    token: userContext.user.idToken,
                    index: currentVideo
                },
                method: "POST",
            })
            const newTranscript = response.data.body;
            console.log(newTranscript)
            if (newTranscript != undefined) {
                setSubtitle(newTranscript.data)
                const newBlob = new Blob([new Uint8Array(newTranscript.data)], { type: "text/vtt" })
                console.log(newBlob)
                setSubtitle(URL.createObjectURL(newBlob))
            }
        } catch (e) {
            console.log(e)
        }
    }, [assesmentIndex, assesmentArray])

    const [progressData, setProgressData] = useState(null)
    const progressFunction = async () => {
        const details = await axios({
            url: "https://backend.keewesolutions.com/courseIELTS/getrecord",
            data: {
                'courseId': location.state.details.id,
                'userId': JSON.parse(localStorage['dbu.lmsStorage']).user.id
            },
            method: "POST"
        })
        console.log(details.data.response.Item)
        //alert(details.data.response.Item)
        setProgressData(details.data.response.Item)
    }

    useEffect(async () => {
        try {
            const details = await axios({
                url: "https://backend.keewesolutions.com/courseIELTS/getrecord",
                data: {
                    'courseId': location.state.details.id,
                    'userId': JSON.parse(localStorage['dbu.lmsStorage']).user.id
                },
                method: "POST"
            })
            console.log(details.data.response.Item)
            //alert(details.data.response.Item)
            // setProgressData(details.data.response.Item)
            const { progressRecord } = details.data.response.Item
            let m = {}

            let mapData = location.state.courseContent.data
            for (let module of mapData) {
                for (let topic of module.topics) {
                    for (let subtopic of topic.subTopics) {
                        m[subtopic.id] = 0
                    }
                }
            }
            // m is the object with the subtopics in order
            console.log(m)
            let cVideo = location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].subTopics[currentDetails.sI].id
            for (let [key, val] of Object.entries(m)) {
                let dataArr = location.state.courseContent.data;
                let module = dataArr[currentDetails.mI];
                if (progressRecord[key] === 0 && weeklyRecord[module.id] != 1) {
                    setVUrl(key)
                    cVideo = key
                    break;
                }
            }
            console.log(cVideo)
            let newMapData = location.state.courseContent.data
            let cm, ct, cs
            for (let module = 0; module < newMapData.length; module++) {
                cm = module
                for (let topic = 0; topic < newMapData[module].topics.length; topic++) {
                    ct = topic
                    for (let subtopic = 0; subtopic < newMapData[module].topics[topic].subTopics.length; subtopic++) {
                        if (newMapData[module].topics[topic].subTopics[subtopic].id === cVideo) {
                            cs = subtopic
                            break;
                        }

                    }
                    if (cs != undefined) {
                        break;
                    }
                }
                if (cs != undefined) {
                    break;
                }
            }
            console.log(cm)
            console.log(ct)
            console.log(cs)
            if (cm != undefined && ct != undefined && cs != undefined) {
                let newDetailsCurrent = {
                    mI: cm,
                    tI: ct,
                    sI: cs
                }
                console.log(newDetailsCurrent)
                setCurrentDetails(newDetailsCurrent)
            }


            await progressFunction()
        } catch (e) {
            console.log("here")
        }
    }, [])



    useEffect(() => {
        async function configureProgress(progressData) {
            if (progressData != null) {
                console.log(progressData)
                const { progressRecord } = progressData
                console.log(progressRecord)
                const len = Object.keys(progressRecord).length
                // const ones = 0
                const valArray = Object.values(progressRecord)
                console.log(valArray)
                const ones = valArray.filter(val => val === 1).length
                setPercentage(() => {
                    console.log(ones)
                    console.log(len)
                    const ans = Math.round((ones / len) * 100)
                    return parseFloat(ans).toFixed(2)
                })
                const keyArrStraight = Object.keys(progressRecord)
                const keyArr = keyArrStraight.reverse()
                console.log(keyArr)

                let m = {}

                let mapData = location.state.courseContent.data
                for (let module of mapData) {
                    for (let topic of module.topics) {
                        for (let subtopic of topic.subTopics) {
                            m[subtopic.id] = 0
                        }
                    }
                }
                // m is the object with the subtopics in order
                console.log(m)
                let cVideo = location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].subTopics[currentDetails.sI].id
                for (let [key, val] of Object.entries(m)) {
                    let dataArr = location.state.courseContent.data;
                    let module = dataArr[currentDetails.mI];
                    if (progressRecord[key] === 0 && weeklyRecord[module.id] != 1) {
                        setVUrl(key)
                        cVideo = key
                        break;
                    }
                }
                console.log(cVideo)
                let newMapData = location.state.courseContent.data
                let cm, ct, cs
                for (let module = 0; module < newMapData.length; module++) {
                    cm = module
                    for (let topic = 0; topic < newMapData[module].topics.length; topic++) {
                        ct = topic
                        for (let subtopic = 0; subtopic < newMapData[module].topics[topic].subTopics.length; subtopic++) {
                            if (newMapData[module].topics[topic].subTopics[subtopic].id === cVideo) {
                                cs = subtopic
                                break;
                            }

                        }
                        if (cs != undefined) {
                            break;
                        }
                    }
                    if (cs != undefined) {
                        break;
                    }
                }
                console.log(cm)
                console.log(ct)
                console.log(cs)
                if (cm != undefined && ct != undefined && cs != undefined) {
                    let newDetailsCurrent = {
                        mI: cm,
                        tI: ct,
                        sI: cs
                    }
                    console.log(newDetailsCurrent)
                    // setCurrentDetails(newDetailsCurrent)
                    await updateWeeklyRecord(newDetailsCurrent.mI)
                }
            }
        }
        configureProgress(progressData)

    }, [progressData])


    //  For unlocking the final assessment
    useEffect(() => {
        async function unlockAssessment(progressData) {
            if (progressData != null) {
                console.log(progressData)
                const { progressRecord } = progressData
                console.log(progressRecord)
                const len = Object.keys(progressRecord).length
                // const ones = 0
                const valArray = Object.values(progressRecord)
                console.log(valArray)
                const ones = valArray.filter(val => val === 1).length
                const ans = Math.round((ones / len) * 100)
                const percentage = parseFloat(ans).toFixed(2)
                if (percentage == 100) {
                    setShowAssesmentsButton(true)
                }

            }
        }
        unlockAssessment(progressData)


    }, [progressData])


    const updateProgressRecord = async () => {
        try {
            // console.log("ASAS")
            const update = await axios({
                url: "https://backend.keewesolutions.com/courseIELTS/updateRecord",
                data: {
                    'courseId': location.state.details.id,
                    'userId': JSON.parse(localStorage['dbu.lmsStorage']).user.id,
                    'subtopicId': vUrl,
                    "progressRecord": progressData.progressRecord
                },
                method: "POST"
            })
            console.log(update.data)
            await progressFunction()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const getRecord = async () => {
            try {
                const record = await axios({
                    url: 'https://backend.keewesolutions.com/monthlyIELTS/getWeeklyRecord',
                    // url:'https://backend.keewesolutions.com/weekly/getWeeklyRecord',
                    method: "POST",
                    data: {
                        courseId: location.state.courseId,
                        userId: JSON.parse(localStorage.getItem("dbu.lmsStorage")).user.id
                    }
                })
               
                setWeeklyRecord(record.data.response)

            } catch (error) {
                console.log(error)
            }
        }
        getRecord()
    }, [currentDetails])
    const configureFinalAssesment = async (progressData) => {
        try {
            const zeroArr = progressData.filter(val => val === 0)
            if (!zeroArr.length) {
                // Network call    
                // based on the result of network call do shit
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [ScorePercentage, setScorePercentage] = useState(0);
    const [TimeStamp, setTimeStamp] = useState(Date.now())
    useEffect(async () => {
        await axios({
            url: "https://cms.keewesolutions.com/getFinalAssesment",
            method: "post",
            data: {
                'courseId': location.state.details.id,
                'userId': JSON.parse(localStorage['dbu.lmsStorage']).user.id,
            }
        }).then(res => {
            const data = res.data.Item;
            if (data != undefined) {
                const totalQue = (data.questions).length;
                let Score = (data.score / totalQue) * 100;
                setScorePercentage(Score);
                setTimeStamp(data.TimeStamp);
            }

        })
    }, [])

    return (
        <>
            <Grid sx={{ marginLeft: '1%' }}>
                <Grid container>
                    <Grid item lg={12} xs={12}>
                        <Typography variant="h1" paddingBottom={'1%'} fontSize="2rem" >
                            {
                                location.state.details.title
                            }
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Breadcrumbs aria-label="breadcrumb" paddingBottom={'1%'}>
                            {/* <Chip label={location.state.courseContent.data[currentDetails.mI].name} />
                            <Chip label={location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].name} />
                            <Chip label={location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].subTopics[currentDetails.sI].name} /> */}
                            <Chip sx={{ marginBottom: "5px" }} label={location.state.courseContent.data[currentDetails.mI].name} />
                            <Chip sx={{ marginBottom: "5px" }} label={location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].name} />
                            <Chip sx={{ marginBottom: "5px" }} label={location.state.courseContent.data[currentDetails.mI].topics[currentDetails.tI].subTopics[currentDetails.sI].name} />
                        </Breadcrumbs>
                    </Grid>

                    <Grid lg={7.6} xs={12} sm={8} md={8} >
                        <div onContextMenu={e => e.preventDefault()}>
                            {
                                !showAssesments ? <>{console.log(vUrl)}
                                    {vData.length == 0 && vUrl != null ? <video src={
                                        "https://storage-mediaoutput.s3.ap-south-1.amazonaws.com/" + "" + vUrl + ".mp4SAMPLE.mp4"
                                        // vid
                                    } controls
                                        controlsList="nodownload"
                                        width="100%"
                                        onEnded={async () => {
                                            await updateProgressRecord()
                                            handleMcqs()

                                        }}>
                                        <track kind='captions' src={subTitle} />
                                        
                                        {/* <caption>assf</caption> */}
                                    </video> : <Typography>{alert("Upcoming Module will open next week")}</Typography>}
                                </> :
                                    <>
                                        <Box>
                                            <h2>Assesment Time</h2>

                                            {console.log(currentAssesment)}
                                            {currentAssesment != null ? (

                                                <FormControl sx={{
                                                    paddingLeft: "24px"
                                                }}>
                                                    <FormLabel id="demo-radio-buttons-group-label" sx={{
                                                        color: "black",
                                                        fontSize: "20px"
                                                    }}>

                                                        {parse(currentAssesment.content.question)}
                                                        {/* {functioen(){
                                                                console.log(ReactDOMServer.renderToString(currentAssesment.content.question))
                                                            }()} */}
                                                        {/* {<currentAssesment.content.question/>} */}
                                                        {/* {ReactDOMServer.renderToString(currentAssesment.content.question)} */}
                                                    </FormLabel>
                                                    {currentAssesment.type == 9 ? <>
                                                        <Button disabled={!disable[assesmentIndex] ? false : true} variant="contained" component="label"
                                                            color={solutionFile && solIndex[assesmentIndex] ? "success" : "primary"}>
                                                            Upload Your Answer
                                                            <input hidden onChange={(e) => {
                                                                setQid(currentAssesment.content.id)
                                                                setSolutionFile(e.target.files[0])
                                                                setSolIndex({ ...solIndex, [assesmentIndex]: true })
                                                            }} type="file" />
                                                        </Button>
                                                        <Button disabled={solutionFile && !disable[assesmentIndex] && solIndex[assesmentIndex] ? false : true} onClick={() => {
                                                            let x = assesmentIndex
                                                            solutionUpload(x, currentAssesment.id)
                                                        }}>Save</Button>
                                                        {showSolution[assesmentIndex] == true && <a href={currentAssesment.content.correctAnswer} style={{ textDecoration: "none" }}><Button>Download Solution</Button></a>}
                                                    </> : <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        sx={{
                                                            paddingLeft: "12px",
                                                            marginBottom: "12px",
                                                            paddingTop: "12px"
                                                        }}
                                                        onChange={e => {
                                                            if (!correctAnswer) {
                                                                setCorrectAnswer(true)
                                                            }

                                                            let rightIndex = 0
                                                            if (e.target.value == currentAssesment.content.correctOption) {

                                                                let newScoreObject = { ...scoreObject }
                                                                newScoreObject[currentAssesment.id] = 1;
                                                                setScoreObject(newScoreObject)
                                                                setCorrectAnswer(e.target.value)
                                                                for (let option in currentAssesment.content.options) {

                                                                    if (currentAssesment.content.options[option].value == e.target.value) {

                                                                        rightIndex = option

                                                                        break
                                                                    }
                                                                }
                                                            } else {
                                                                for (let option in currentAssesment.content.options) {

                                                                    if (currentAssesment.content.options[option].value == e.target.value) {
                                                                        console.log(e.target.value)
                                                                        console.log(currentAssesment.content.options[option].value)
                                                                        rightIndex = option
                                                                        console.log(typeof rightIndex)
                                                                        break
                                                                    }
                                                                }
                                                                let newScoreObject = { ...scoreObject }
                                                                newScoreObject[currentAssesment.id] = 0;
                                                                setScoreObject(newScoreObject)
                                                            }
                                                            // console.log(rightIndex)
                                                            setSelectedOption(parseInt(rightIndex))
                                                        }
                                                        }
                                                    >
                                                        {
                                                            currentAssesment.content.options.map((assesment, assesmentIndex) => {
                                                                return (<FormControlLabel value={assesment.value} control={<Radio />} label={assesment.name} key={assesment.value + "1"} sx={
                                                                    {
                                                                        color: correctAnswer === false ? 'black' : (correctAnswer === assesment.value && selectedOption === assesmentIndex ? 'green' : (selectedOption == assesmentIndex ? "red" : 'black'))
                                                                    }
                                                                } onClick={() => {
                                                                    console.log(correctAnswer)
                                                                }} />)
                                                            })
                                                        }

                                                    </RadioGroup>}

                                                    <Box sx={{
                                                        display: "flex"
                                                    }}>
                                                        <Button onClick={() => {
                                                            setCorrectAnswer(false)
                                                            setSolIndex({ ...solIndex, [assesmentIndex]: false })
                                                            setAssesmentIndex(prev => {
                                                                if (prev != 0) {
                                                                    return prev - 1
                                                                } else {
                                                                    handleVideo()
                                                                    return prev
                                                                }
                                                            })
                                                        }}>
                                                            {assesmentIndex != 0 ? "Back" : null}
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setSolIndex({ ...solIndex, [assesmentIndex]: false })
                                                                if (assesmentIndex == assesmentArray.length - 1) {
                                                                    let totalScore = 0
                                                                    for (let [key, value] of Object.entries(scoreObject)) {
                                                                        if (value == 1) {
                                                                            totalScore++;
                                                                        }
                                                                    }
                                                                    if (totalScore / Object.keys(scoreObject).length < 0.5) {
                                                                        alert("low score");
                                                                        return null;
                                                                    }
                                                                }
                                                                setCorrectAnswer(false)
                                                                setAssesmentIndex(prev => {
                                                                    if (prev != assesmentArray.length - 1) {
                                                                        return prev + 1
                                                                    } else {
                                                                        console.log("here handle video stops")
                                                                        handleVideo()
                                                                        return prev
                                                                    }
                                                                })
                                                            }}
                                                        >{
                                                                assesmentIndex == assesmentArray.length - 1 ? "Submit" : "Next"

                                                            }</Button>
                                                    </Box>
                                                </FormControl>
                                            ) : null}
                                        </Box>
                                    </>
                            }

                        </div>

                        <Divider sx={{ width: '100%', marginLeft: '2.5%' }} />

                    </Grid>
                    <Grid lg={4.4} xs={12} sm={4} md={4} sx={{ justifyContent: 'center' }}>
                        {/* <Grid lg={12} xs={12} sm={12} md={12}></Grid> */}
                        {data == "1" ? (<Hour setUrl={setVUrl} currentDetails={setCurrentDetails} courseContent={location.state.courseContent} setAssesment={setShowAssesments} progressData={progressData} />) : (<Appaa weeklyRecord={weeklyRecord} setUrl={setVUrl} currentView={currentDetails} currentDetails={setCurrentDetails} courseContent={location.state.courseContent} setAssesment={setShowAssesments} progressData={progressData} />)}

                        <div style={{textAlign: 'center'}}>
                            {percentage>=80?<Button  sx={{backgroundColor: '#c55524'}} onClick={() => {
                                if(studata.fathername==""){
                                    alert1.show("complete your profile details first")
                                }
                                else if(studata.mothername==""){
                                    alert1.show("complete your profile details first") 
                                }
                                else if(studata.qualification==""){
                                    alert1.show("complete your profile details first")

                                }
                                else if(studata.aadhar==""){
                                    alert1.show("complete your profile details first")
                                }
                                else if(studata.aadharImg==""){
                                    alert1.show("complete your profile details first")
                                }
                                else if(studata.ImageUrl==""){
                                    alert1.show("complete your profile details first")
                                }
                                else { navigate('/exam', {
                                    state: {
                                        userObj: JSON.parse(localStorage.getItem("dbu.lmsStorage")), 
                                        courseId: location.state.courseId }
                                })}
                            }} variant="contained">Final Assessment</Button>:""}
                        
                        </div>

                        {/* <div style={{textAlign: 'center',paddingTop:'10px'}}>
                            {ScorePercentage>=70?<Button  sx={{backgroundColor: '#c55524'}} onClick={() => {
                                navigate('/certificate', {
                                    state: {
                                        userName:userContext.user.name, 
                                        courseId: location.state.courseId,
                                        courseName:location.state.courseContent.name,
                                        duration:location.state.details.duration,
                                        Date:new Intl.DateTimeFormat('en-US', { year: 'numeric', month: "long", day: 'numeric' }).format(TimeStamp)
                                     }
                                })
                                
                            }} variant="contained">Download Certificate</Button>:""}
                            {console.log(location.state)}
                        </div> */}
                    </Grid>
                    <Grid lg={7.6} xs={12} sm={8} md={8}>
                        <Newcourse />
                    </Grid>

                    <Grid lg={0.4} ></Grid>
                    <Grid container item lg={4} xs={12} sm={4} md={4}>

                        <Currentpro percentage={percentage} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
