import { Button, Collapse, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import './CurrentLAnding.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RecipeReviewCard from './Card';
import RecipeReviewCard1 from './Card1';
import RecipeReviewCard2 from './Card2';
import { Link, NavLink } from 'react-router-dom';
import CourseLayout from './CourseLayout';
import Typography1 from '../Typography';
import { Divider } from '@mui/material';
import Ladka from '../../../assets/images/DashImage/Ladka2.png';
import './CourseLAyout.css';
import axios from 'axios';
import NestedList from "./NewCourseHead";
import image from '../../../assets/images/DashImage/Ladka2.png';
import { UserContext } from 'ContextFiles';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import LoadingScreen from './Loading.gif'


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


export default function CurrentLanding(props) {
    const [page, setPage] = useState(true);
    const [recordsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1)
    const [value, setValue] = useState(0);
    const userContext = useContext(UserContext)
    const [dataArr, setDataArr] = useState([])
    const [nPages, setNpages] = useState(0);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const [loading, setLoading] = useState(false)
    const sendPostRequest = async (event) => {
        try {
            console.log(userContext.user.id)
            var courseIds = [];
            if (userContext.user.email != 'onlinecourses@mrsptu.ac.in') {
                const response = await axios({
                    // /'url: process.env.REACT_APP_URL + '/db/getcourseslms',
                    url: "https://backend.keewesolutions.com/dbIELTS/getcourseslms",
                    method: "POST",
                    data: {
                        token: userContext.user.idToken,
                        userId: userContext.user.id
                    }
                })
                courseIds = response.data.data;
                console.log(response.data)
                console.log(courseIds)

            }
            else {
                const response = await axios({
                    url: 'https://cms.keewesolutions.com/courses/get',
                    // url: "http://localhost:8081/db/getcourses",
                    method: "get",
                })
                const items = response.data.Items
                console.log(response.data.Items)
                for (let i in items) {
                    courseIds.push(items[i].courseId);
                }
            }
            setNpages(Math.ceil(courseIds.length / recordsPerPage));
            const courseData = await axios({
                //  url: process.env.REACT_APP_URL + "/db/fetchcoursedata",
                //  url: "https://backend.keewesolutions.com/db/fetchcoursedata",
                url: "https://backend.keewesolutions.com/dbIELTS/fetchcoursedata",
                method: "POST",
                data: {
                    token: userContext.user.idToken,
                    courseIds: JSON.stringify(courseIds.slice(indexOfFirstRecord, indexOfFirstRecord + 50))
                }
            })
            console.log(courseData.data)
            if (courseData.data == "no course for your accounts") {
                return
            }
            if (courseData.data == "error") {
                return
            }
            console.log(courseData)

            setDataArr(courseData.data.data)

        } catch (err) {
            console.log(err)
        }
    };

    const [qwerty, setQwerty] = useState([]);
    useEffect(() => {
        sendPostRequest()
        setLoading(true)

    }, [currentPage])

    const [opencollapse, setopencollapse] = useState(false)
    const [readMore, setReadMore] = useState(false);

    return (
        <>
            <Grid container item sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
                <Typography color={'#364045'} fontWeight={"500"} fontSize={"40px"} marginBottom='2%'>
                    Course Library
                </Typography>

                {loading === true ?
                    (<>
                        <Grid   >
                            <Search className='searches' sx={{ border: '1px solid grey', margin: '15px 0', borderRadius: '10px', backgroundColor: '#f5f5f5' }}>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    className='search1'
                                    placeholder="Search Courses Here"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={e => {
                                        setValue(e.target.value)
                                    }}
                                />
                            </Search>
                        </Grid>

                        <Grid display={'flex'} container sx={{ justifyContent: 'flex-box', paddingLeft: '10%' }} spacing={2}>
                            {dataArr.filter(row => {

                                if (value == "") {
                                    return row
                                }
                                else if (row.courseDetails.title.toLowerCase().includes(value.toLowerCase())) {
                                    return row;
                                }
                            }).map(product => {


                                return (

                                    <RecipeReviewCard key={product.id} {...product} />
                                )
                            })}
                        </Grid>
                        <Button onClick={e => {
                            setCurrentPage(currentPage - 1)
                        }}>Back</Button>
                        <Button onClick={e => {
                            setCurrentPage(currentPage + 1)
                        }}>Next</Button>
                    </>) :
                    <img src={LoadingScreen} alt="Loading" class="center" />}
            </Grid>
        </>
    );

}
