import React, { useContext, useEffect } from 'react';
import { Grid, styled, emphasize, Chip, Breadcrumbs, Typography, Button, Stack, Modal, Box, TextField, Link, Card } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import bg from "../ILETS/Images/bg.png";
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Form, NavLink } from 'react-bootstrap';
import axios from 'axios';
import Syllabus from './Syllabus';
import './footer.css';
import "./re.css";
import { UserContext } from 'ContextFiles';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'rgb(234 234 234 / 57%)',
  border: '1px solid rgb(234 234 234 / 57%)',
  boxShadow: 30,
  p: 4,
  borderRadius: '14px',
  padding: '0px'
};

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const Applynow = (data) => {
  console.log(data)
  const authState = useContext(UserContext)
  console.log(authState)
  const [Course, setCourse] = useState({})
  const [data1, setData] = useState({})
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open, setOpen] = React.useState(false);
  const syllabusOpen = () => setOpen(true);
  const syllabusClose = () => setOpen(false);
  const [url, seturl] = useState()
  console.log(data.data)

  const course = async (data) => {

    await axios({
      url: "https://backend.keewesolutions.com/courseIELTS/allCourses",
      method: "post",
    }).then((response) => {
      console.log(response.data.Items)
      let arr = []
      for (let i in response.data.Items) {
        if (response.data.Items[i].courseId == data) {
          arr.push(response.data.Items[i])
        }
      }
      setCourse(arr[0])
    })
  }
  const navigate = useNavigate()
  console.log(authState.user)
  const handleuser = async (price) => {
    if (authState.authenticated) {
      await axios({
        url: "https://backend.keewesolutions.com/payment/create",
        method: "POST",
        data: {
          Email: authState.user.email,
          name: authState.user.name,
          UserId: authState.user.id,
          Phone: authState.user.Phone.split(''),
          courseId: location.search.split("?")[1],
          price: price
        }
      }).then(res => {
        seturl(res.data.data)
        location.href = res.data.data
      })
    }
    else {
      navigate({
        pathname: "/login",
        search: location.search + "&" + price
      })
    }
  }
  useEffect(() => {
    course(data.data)
  }, [])
  const submit = async (e) => {
    console.log(data1)
    axios({
      method: "post",
      url: "https://backend.keewesolutions.com/mailer/registerforielts",
      data: data1,
    }).then(response => console.log(response))
    e.preventDefault();


  }
  const handlehover = (e) => {
    e.target.style.background = 'red';
  }
  return (
    <>
      <Grid className='a1' container sx={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundColor: '#ffffff' }}>
        <Grid item lg={1} ></Grid>
        <Grid item className="in" lg={5} xs={12} md={6} sm={6} sx={{ padding: '1%', marginBottom: '4%', marginTop: '2%' }}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <NavLink href="https://www.ieltsplus.deshbhagatglobalservices.com/#/">
                <StyledBreadcrumb
                  component="a"

                  label="Home"
                  icon={<HomeIcon fontSize="small" href="/#" />}
                />
              </NavLink>

              <StyledBreadcrumb component="a" href="#" label="Course Details" />

            </Breadcrumbs>
          </div>
          {/* <img src={dbu} alt="" style={{ width: '150px', height: '100px' }} /> */}
          <Typography className='hed' sx={{ fontFamily: 'Roboto', letterSpacing: '.7px', fontSize: '38px', lineHeight: '42px', color: '#272c37', fontWeight: '700', marginTop: '3%' }}>
            {Course.title}
          </Typography>
          <Typography className='hed1' sx={{ fontFamily: 'Roboto', fontSize: '16px', lineHeight: '22px', color: '#51565e', fontWeight: 'normal', marginTop: '16px', textAlign: 'justify' }}>
            {Course.description}
          </Typography>
          <Stack spacing={2} direction="row" sx={{ marginTop: '6%' }}>

            <Button variant='contained' className='bu1' sx={{ width: '90%' }} onClick={handleOpen1}  >
              Pay
            </Button>
            <Button variant='outlined' className='bu2' sx={{ width: '90%' }} onClick={syllabusOpen} >
              Download Syllabus
            </Button>
          </Stack>

        </Grid>
        <Grid className='imc1' item lg={5} xs={12} md={6} sm={6} sx={{ padding: '1%', marginTop: '4%' }}>
          <img className='imc' src={"https://dbucourse.s3.amazonaws.com/" + Course.courseId + ".png"} alt="" style={{ width: '100%', height: '411px', borderRadius: '6px', border: '1px solid #fff' }} />
        </Grid>

        <Grid item lg={1}></Grid>
      </Grid>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="res" sx={style} >

          <Grid sx={{ padding: "25px", mb: '2%', borderRadius: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: '24px', fontWeight: 700, }}> Click on the card to pay for</Typography>
              <CloseIcon onClick={handleClose1} sx={{ color: '#fff', fontWeight: '300', cursor: 'pointer' }} />
            </div>

            <Grid container >
              {/* <Grid item xs={12} lg={3} md={3} sm={3} paddingRight={'3%'}>
                <Card onClick={() => handleuser("2949")} sx={{ border: '1px solid rgba(128, 128, 128, 0.4)', borderRadius: '18px', margin: '17px 0px', "&:hover": { backgroundColor: "#2196f3" } }}>
                  <div style={{ borderRadius: '12px', margin: '8px', minHeight: '120px', padding: '12px 20px', backgroundColor: '#2196f3' }} >

                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, fontFamily: 'Roboto', color: '#fff' }}>1 Month</Typography>
                    <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, fontFamily: 'Roboto', color: 'rgba(255, 255, 255, 0.7)' }}>₹ 2949</Typography>

                  </div>
                </Card>

              </Grid> */}
              {/* <Grid item xs={12} lg={3} md={3} sm={3} paddingRight={'3%'}>
                <Card onClick={() => handleuser("8260")} sx={{ border: '1px solid rgba(128, 128, 128, 0.4)', borderRadius: '18px', margin: '17px 0px', "&:hover": { backgroundColor: "#2196f3" } }}>
                  <div style={{ borderRadius: '12px', margin: '8px', minHeight: '120px', padding: '12px 20px', backgroundColor: '#2196f3' }}>

                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, fontFamily: 'Roboto', color: '#fff' }}>3 Month</Typography>
                    <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, fontFamily: 'Roboto', color: 'rgba(255, 255, 255, 0.7)' }}>₹ 8260</Typography>

                  </div>
                </Card>

              </Grid> */}
              {/* <Grid item xs={12} lg={3} md={3} sm={3} paddingRight={'3%'}>
                <Card onClick={() => handleuser("15930")} sx={{ border: '1px solid rgba(128, 128, 128, 0.4)', borderRadius: '18px', margin: '17px 0px', "&:hover": { backgroundColor: "#2196f3" } }}>
                  <div style={{ borderRadius: '12px', margin: '8px', minHeight: '120px', padding: '12px 20px', backgroundColor: '#2196f3' }}>

                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, fontFamily: 'Roboto', color: '#fff' }}>6 Month</Typography>
                    <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, fontFamily: 'Roboto', color: 'rgba(255, 255, 255, 0.7)' }}>₹ 15930</Typography>

                  </div>
                </Card>
              </Grid> */}
              <Grid item lg={3} ></Grid>
              <Grid item xs={12} lg={6} md={3} sm={12} >
                <Card onClick={() => handleuser("33040")} sx={{ border: '1px solid rgba(128, 128, 128, 0.4)', borderRadius: '18px', margin: '17px 0px', "&:hover": { backgroundColor: "#2196f3" } }}>
                  <div style={{ borderRadius: '12px', margin: '8px', minHeight: '120px', padding: '12px 20px', backgroundColor: '#2196f3' }}>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, fontFamily: 'Roboto', color: '#fff' }}>12 Month</Typography>
                    <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, fontFamily: 'Roboto', color: 'rgba(255, 255, 255, 0.7)' }}>₹ 33040</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid lg={3} ></Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={syllabusClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        Updated upstream
      >
        <Syllabus Id={data.data} />




      </Modal>
    </>

  )
}

export default Applynow

