import React, { useEffect, useState } from 'react';
import Applynow from './Applynow';
import Duration from './Duration';
import Navbar from './Navbar';
import Footer from './footer';
import LearningPath from './LearningPath';
import { useLocation } from 'react-router-dom';
import TopNav from './TopNav';
import { Grid } from '@mui/material'
import Overview from './courseoverview'
import axios from 'axios';

const CourseContent = () => {
  const location = useLocation()
   console.log(location.search.split("?")[1])
  const id = location.search.split("?")[1]
  return (
    <>
    <Grid container>
       <TopNav/>
      <Applynow data={id}/>
      <Duration/>
      <Overview data ={id}/> 
      <LearningPath id={id}/>
      <Footer/>
    </Grid>
     
    </>
  )
}

export default CourseContent
