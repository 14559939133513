import React, { useEffect, useState } from 'react';
import Footer from './footer';
import { useLocation } from 'react-router-dom';
import TopNav from './TopNav';
import { Grid } from '@mui/material'


const Terms = () => {

    return (
        <>
            <Grid container>
                <TopNav />
                <Grid item lg={1} xs={1} md={1} sm={1}></Grid>
                <Grid item lg={10} xs={10} md={10} sm={10} sx={{}}>
                    <div >

                        <h3 style={{ textAlign: "center" }}>
                            Terms and Conditions
                        </h3>
                    </div>
                    <div >
                                <ol style={{marginTop:'2%', textAlign: 'justify' }}>
                                    <li>
                                        Candidates shall abide by the Rules & Regulations in force as well as those that may be issued by the Desh Bhagat Global Services from time to time.
                                    </li>
                                    <li>
                                        Admission made to any certification is provisional till the time the Desh Bhagat Global Services finally approves it by mail.
                                    </li>
                                    <li>
                                        A false declaration or misrepresentation of qualification or eligibility by the candidate shall nullify his/her admission to the Desh Bhagat Global Services  and attract appropriate penal action, including forfeiture of fees.
                                    </li>
                                    <li>
                                        The content of the pages of this website is for your general information and use only. It is subject to change without notice.
                                    </li>
                                    <li>
                                        The use of any information or materials on this website is entirely at your own risk, for which we shall not be liable.
                                    </li>
                                    <li>
                                        It is the  responsibility of the user to ensure that any products, services or information available through this website meet your specific requirements.
                                    </li>
                                    <li>
                                        Desh Bhagat Global Services contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics.
                                    </li>
                                    <li>
                                        Copying is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                                    </li>
                                    <li>
                                        Unauthorized use of this website by you may give rise to a claim for damages and/or be a criminal offense.
                                    </li>
                                    <li>
                                        We do not signify that we endorse the third parties.
                                    </li>
                                    <li>
                                        We take no responsibility for the content of the linked to the website.
                                    </li>
                                    <li>
                                        You may not create a link of this website to other, without Desh Bhagat Global Services permission. Your use of this website or any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.
                                    </li>
                                </ol>
                            </div>
                </Grid>
                <Grid item lg={1} xs={1} md={1} sm={1}></Grid>
                <Footer />
            </Grid>

        </>
    )
}

export default Terms
