import React from 'react';
import { Typography, Box, Button, Grid, Link } from '@mui/material';
import img1 from '../images/tieup.jpg';
import { NavLink } from 'react-router-dom';
import "./footer.css"

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(1.5)' }}
  >
    •
  </Box>
);
const MyOpen = () => {
  return (

    
      <Grid container sx={{padding: '2%', backgroundColor:"white" }}>
        <Grid item lg={1}/>
        <Grid item xs={12} lg={5} sm={6} md={6} sx={{ marginTop: '' }} >
          <Typography  className='text12' sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#222', letterSpacing: '-.25px', fontSize: '36px' }}>
           Our Tie Ups
          </Typography>
          <Typography  className='text123' sx={{ fontFamily: 'Roboto', fontWeight: '600', color: '#222', letterSpacing: '-.25px', fontSize: '2.5rem', marginBottom: '' }}>
            Global Partner of
          </Typography>
          <Typography className='text123' sx={{ fontFamily: 'Roboto', fontWeight: '600', color: '#333', fontSize: '2.5rem', marginBottom: '1rem' }}>
           1800+ Universities
          </Typography>
          <Typography className='text12' sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#333', fontSize: '1.05rem', marginBottom: '3%' }}>
          As a leading study abroad services provider, we help our students to reach their study destinations hassle-free.
          </Typography>
          
<div className='we1'>
  <NavLink  to={'/contact'} style={{textDecoration:'none'}}>
            <Button variant="contained" sx={{ borderRadius:'0',width:'187px',height:'48.6px', backgroundColor: '#29313C', fontWeight: '700',  '&:hover': { backgroundColor: '#1260cd', border: '0px solid black', color: 'black' }, fontSize: '15px', padding: '10px', color: 'white', letterSpacing:'2px' }} ><Link to='https://deshbhagatglobalservices.com/countries/'>Read More <i class='fas fa-arrow-right' ></i></Link></Button>
          </NavLink>
</div>
          
        </Grid>




        <Grid item xs={12} lg={5} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>

          <img className="worldmap" src={img1} alt="" style={{ marginTop:'35px', width: '85%', height: 'fit-content' }} />
        </Grid>
        <Grid item lg={1}  />
      </Grid>
  
  )
}

export default MyOpen