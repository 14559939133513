import React, { useContext } from "react";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Link,
  Select,
  MenuItem,
  InputLabel,
  Modal,
  Paper,
  Box
} from "@mui/material";
import VectorNew from "../components/VectorNew.png"
import PasswordBox from "./PasswordBox";
import img from "./crest.png";
import { NavLink } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import google from "./google.png";
import apple from "./apple.png";
import facebook from "./facebook.png";
import microsoft from "./microsoft.png";
import { useState, useEffect } from "react"
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from "ContextFiles";
import { useAlert } from "react-alert";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { data } from "views/dashboard/Scheduler/Grouping";
import Supportcenter from "./Supportcenter";
import tick from "./tick.png"
import Container from "@mui/material/Container";



const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Register() {

  const alert = useAlert();
  const [code, setCode] = useState('')
  const contextUser = useContext(UserContext)
  const navigate = useNavigate();

  const location = useLocation();
  if (location.state != null) {
    console.log(location.state.user)
  }


  const [loc, setloc] = useState(location.search.split("="))



  const initialValues = { username: "", email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [url, seturl] = useState()

  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  }

  const handleClick = () => {
    alert("path not set")
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

  }

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
    //   axios({
    //     url:"https://backend.keewesolutions.com/payment/create",
    //     method:"POST"
    //   }).then(res=>{
    //     console.log(res.data.data)
    //   seturl(res.data.data)
    // })
  }, [formErrors])

  useEffect(() => {
    axios({
      url: "https://backend.keewesolutions.com/payment/create",
      method: "POST"
    }).then(res => {
      console.log(res.data.data)
      seturl(res.data.data)
    })
  }, [])

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => {
    console.log("href")
    console.log(url)

  };
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const paperStyle = { padding: 20, height: '80vh', width: "max-content", margin: "20px auto" }
  const [email, setEmail] = useState("");

  const validate = (values) => {
    const errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  }



  const [data, setdata] = useState({
    name: "",
    email: "",
    username: "",
    phone: "",
    country: "",
    password: "",
    ImageUrl: "",
    aadhar: "",
    fathername: "",
    mothername: "",
    qualification: "",
    supportCenter: "",
    showPassword: false,
    emailExists: false,
    // userIdAP:loc[2]!="undefined"?loc[2]:"",
    // userIdSC:loc[3]!="undefined"?loc[3]:""
  });
  console.log(data)
  const togglePassword = () => {
    setdata({ ...data, showPassword: !data.showPassword })
  }

  // const onSubmit = () => {
  //   navigate("/register/step2")
  // }

  // const onSubmit = event => {
  //   event.preventDefault();

  //   if (data.emailExists == false && data.usernameExists == false) {

  //     console.log(data)


  //     navigate(`/register/step2/${location.search}`,{state: data})

  //     //       }
  //     //     })
  //     // },[]);

  //     // console.log(user)
  //   } else if (data.usernameExists == true) {
  //     window.alert("please Change Username")
  //   }
  //   else {
  //     window.alert("please Change email address")
  //   }
  // }
  const onSubmit = (event) => {
    console.log(data.emailExists)
    event.preventDefault();

    if (data.emailExists == false && data.usernameExists == false) {
      axios({
        url: 'https://backend.keewesolutions.com/userIELTS/signup',
        method: 'POST',
        data: {
          name: data.name,
          email: data.email,
          username: data.username,
          password: data.password,
          phone: '+91' + data.phone,
          country: "India"
        }
      })
        .then(
          (response) => {
            console.log("reponse new ", response)
            //  if(response.status==200){
            //    axios({
            //     url:"https://cms.keewesolutions.com/mailer/registrationmail",
            //     method:"post",
            //     data:{email:data.email}
            //    }).then((res)=>{
            //     console.log(res)
            //    })
            //  }
            console.log(response)
            if (response.data === 'UsernameExistsException') {
              window.alert("Public Username is not available. please change and retry registering")
              setdata({
                name: "",
                email: "",
                username: "",
                phone: "",
                country: "",
                password: "",
                showPassword: false
              })

              return;
            }
            else if (response.data === 'CodeDeliveryFailureException') {
              window.alert("Error while Registering - try again!")
              setdata({
                name: "",
                email: "",
                username: "",
                phone: "",
                country: "",
                password: "",
                showPassword: false
              })
            } else {
              console.log(data)
              // let formdata = new FormData();
              // formdata.append('email', data.email)



              axios({
                url: 'https://cms.keewesolutions.com/user/putUserData',
                method: 'POST',
                data: data
              }).then((res) => {
                console.log(res)
                // handleOpen1()


                axios({
                  url: 'https://backend.keewesolutions.com/userIELTS/login',
                  method: 'POST',
                  data: {
                    username: data.username,
                    password: data.password
                  }
                })
                  .then((response) => {
                    console.log(response)
                    if (response.data.accessToken !== undefined) {
                      let userData = response.data
                      let newUserContext = { ...contextUser }
                      newUserContext.authenticated = true
                      let newUser = {
                        id: userData.idToken.payload['cognito:username'],
                        name: userData.idToken.payload.name,
                        email: userData.idToken.payload.email,
                        idToken: userData.idToken.jwtToken,
                        refreshToken: userData.refreshToken
                      }
                      newUserContext.user = newUser
                      contextUser.setNewUser(newUserContext)

                      let localStorageObject = JSON.stringify({
                        isAuthenticated: true,
                        user: newUser
                      })
                      localStorage.setItem("dbu.lmsStorage", localStorageObject)
                      console.log(newUser)
                      if (location.search) {
                        axios({
                          url: "https://backend.keewesolutions.com/payment/create",
                          method: "POST",
                          data: {
                            Email: newUser.email,
                            name: newUser.name,
                            UserId: newUser.id,
                            Phone: ("+91"+data.phone).split(''),
                            courseId: location.search.split("?")[1].split("&")[0],
                            price: location.search.split("?")[1].split("&")[1]
                          }
                        }).then(res => {
                          console.log(res.data.data)
                          seturl(res.data.data)
                          console.log("payment", location.search)
                          // location.href=res.data.data
                          window.location.href = res.data.data
                        })
                      }
                      else {
                        navigate('/')
                      }
                    }
                  })
              })
            }
          })
      //   },[]);

      // console.log(user)
    } else if (data.usernameExists == true) {
      window.alert("please Change Username")
    } else if (data.password.length < 7) {
      window.alert("password, entered minimum 8 character")
    }
    else {
      window.alert("please entered required field")
    }
  }
  console.log(data)


  return (

    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={event => {
              event.preventDefault();
              axios({
                method: 'post',
                url: "https://backend.keewesolutions.com/userIELTS/getUserByEmail",
                data: {
                  email: event.target.value
                }
              }).then((response => {
                console.log((response.data.Users).length)
                if ((response.data.Users).length != 0) {
                  window.alert("Email already Exists. Please use another Email Address")
                  setdata({ ...data, email: event.target.value, emailExists: true })
                }
                else {
                  setdata({ ...data, email: event.target.value, emailExists: false })
                }
              }
              ))
              setdata({ ...data, email: event.target.value })
            }}

          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            onChange={(e) => setdata({ ...data, name: e.target.value })}

          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Public Username"
            name="username"
            autoComplete="username"
            value={data.username}
            onChange={event => {
              event.preventDefault();
              axios({
                method: 'post',
                url: "https://backend.keewesolutions.com/userIELTS/getUserByUser",
                data: {
                  Username: event.target.value
                }
              }).then((response => {
                console.log((response.data.Users).length)
                if ((response.data.Users).length != 0) {
                  window.alert("Username already taken. Please use another Username ")
                  setdata({ ...data, username: event.target.value.replace(/[^a-z0-9]/, ''), usernameExists: true })
                }
                else {
                  setdata({ ...data, username: event.target.value.replace(/[^a-z0-9]/, ''), usernameExists: false })
                }
              }
              ))
              setdata({ ...data, username: event.target.value.replace(/[^a-z0-9]/, '') })
            }}

          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password, Entered minimum 8 character"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setdata({ ...data, password: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone No."
            name="phone"
            autoComplete="phone"
            onChange={(e) => setdata({ ...data, phone: e.target.value })}
          />


          <Button
            onClick={onSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
          <Grid container>
            {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
            <Grid item>
              <Link href="/login" variant="body2">
                {"Already Registered? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </Box>

      </Box>
    </Container>

  );
}