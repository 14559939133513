import { Button, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import LatestUpdates from './AboutCourses';
// import BasicCard from './CourseCard';
import About from './CourseDetails/About';
import Faqs from '../../../Deepak/Faqs';
import Instructor from './CourseDetails/Instructor';
import Progress from '../../../assets/images/Courses/Progress.png'
import './CourseLAyout.css';
import Reviews from 'Rohan/Comp1';
import Discussions from './CourseDetails/Discussions';
import axios from 'axios';
import Currentpro from 'Rohan/Currentpro';
import Appaa from '../../../vaibhav/coursecontent/Appaa';
import styled from '@emotion/styled';
import Resources from './Resources';


const Button1 = styled(Typography)({
    fontFamily:'Roboto',
    color: '#5541d7', 
    fontSize: '1rem', 
    fontWeight: '500',
    cursor: 'pointer'
    // fontSize: '14px'
})

const Newcourse = () => {
    const location = useLocation()
    console.log(location)
    const [value, setValue] = useState(0);
    const [vUrl, setVUrl] = useState(()=>{
        if(location.state.courseContent.data[0].topics[0]){
            return location.state.courseContent.data[0].topics[0].subTopics[0].id

        }else {
            return null
        }
    })
    // useEffect(async ()=>{
    //     try {
    //         const imgCall = await axios({
    //             url: "https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + location.state.courseId + '.png'
    //         })
    //     } catch (error) {
            
    //     }
    // })
    return (
        <>
            <Grid container sx={{ border: '0px', borderRadius: '0px', minWidth: '100%' }}>
                {/* */}
                <Grid container sx={{ border: '0px', borderRadius: '0px', minWidth: '100%', paddingTop: '3%' }}>
                    <Grid item lg={12} xs={12}>
                        <Grid container item xs={12} lg={12} sx={{
                           
                             borderTopLeftRadius: "12px",
                             borderTopRightRadius: "12px",
                             boxShadow:"" ,
                             marginLeft: '1%'
                        }} >
                            <Grid lg={1.3} xs={3}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(0);
                                        }}
                                        // sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        About
                                    </Button1>
                                </Typography>
                            </Grid>
                            <Grid lg={1.5} xs={3}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(1);
                                        }}
                                        // sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500', marginLeft: '-5%' }}
                                    >
                                        Content
                                    </Button1>
                                </Typography>
                            </Grid>
                            <Grid lg={1.6} xs={3}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(3);
                                        }}
                                        // sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Reviews
                                    </Button1>
                                </Typography>
                            </Grid>
                            <Grid lg={1.7} xs={3}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(5);
                                        }}
                                        // sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Instructor
                                    </Button1>
                                </Typography>
                            </Grid>
                            <Grid lg={1.9} xs={4}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(4);
                                        }}
                                        // sx={{  }}
                                    >
                                        Discussions
                                    </Button1>
                                </Typography>
                            </Grid>
                            <Grid lg={1} xs={3}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(2);
                                        }}
                                        // sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        FAQS
                                    </Button1>
                                </Typography>
                            </Grid>
                            <Grid lg={2} xs={3}>
                                <Typography>
                                    <Button1
                                        onClick={() => {
                                            return setValue(6);
                                        }}
                                        // sx={{ color: '#5541d7', fontSize: '1rem', fontWeight: '500' }}
                                    >
                                        Resources
                                    </Button1>
                                </Typography>
                            </Grid>
                         
                        </Grid>
                        <Divider sx={{ padding: '1% 0%', width: '99%' }} />
                        <Grid container item lg={12} xs={12} sx={{
                            borderTop: "none",
                            borderRadius: "12px",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginX: "12px"
                        }}>
                            {value == 0 ? (
                                <About details={location.state.details} />
                            ) : value == 1 ? (
                                <LatestUpdates courseContent={location.state.courseId} />
                            ) : value == 6 ? (
                                <Resources courseContent={location.state.courseId} />
                            ) : value == 3 ? (
                                <Reviews courseId={location.state.courseId}/>
                            ) : value == 2 ? (
                                <Faqs />
                            ) : value == 5 ? (
                                <Instructor courseId={location.state.courseId}/>
                            ) : value == 4 ? (
                                <Discussions videoId={vUrl} />
                            ) : (
                                ''
                            )}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Newcourse;